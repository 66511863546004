import { Box, makeStyles } from "@material-ui/core";
import React from "react";

export default function SignatureScroll() {
  const classes = useStyles();
  return <Box className={classes.img_container_container}></Box>;
}

const useStyles = makeStyles((theme) => ({
  img_container_container: {
    position: "relative",
    background: theme.palette.text.secondary,
    top: "-60px",
    width: 700,
    boxShadow: "rgba(17, 17, 26, 0.1) 0px 0px 16px",
    height: 500,
    overflowY: "scroll",
    scrollBehavior: "smooth",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}));
