import React, { useState } from "react";
import { Box, ClickAwayListener, Typography } from "@material-ui/core";
import imageMan from "../../assest/images/man.png";
import HowDoesItWorksDataMapped from "../../components/howDoesItWorksCardMaped";
import LegalDocumnet from "../../components/SampleForm/LegalDocumnet";
import FinancalDocument from "../../components/SampleForm/FinacalDocument";

import Divider from "../../components/divider";
import useStyles from "./styles";
import RippleBckground from "../../components/ripplwbackground";
import MarginWrapper from "../../components/marginwrapper";
import Button from "../../components/buttons";
import axe from "../../assest/bgHeavy.png";

import { useDispatch } from "react-redux";
import SearchBothDocs from "../../components/searchBothDocs";

const Home = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  return (
    <>
      <div className={classes.layout}>
        <MarginWrapper>
          <RippleBckground />

          <Box className={classes.topconatiner}>
            <Typography className={classes.textones}>
              CREATE LEGAL AND
              <Typography className={classes.textoness}>
                FINANCIAL DOCUMENTS
              </Typography>
            </Typography>
            <Box>
              <Typography className={classes.textfour}>
                An Innovative System Of Online Forms Guides You Through
                <div>The Creation Of Your Documents</div>
              </Typography>
            </Box>
            <Box className={classes.imageAndSearch} mt={7}>
              <img src={imageMan} alt="" className={classes.image} />
              <Box className={classes.mg} mb={5}>
                <SearchBothDocs />
              </Box>
            </Box>
          </Box>
        </MarginWrapper>
      </div>
      <div className={classes.bgWholeImage}>
        <MarginWrapper>
          <Box mt={8}>
            <Box className={classes.HowItWorkImage}>
              <Box className={classes.textColor}>
                <Typography className={classes.poss} variant="h3">
                  How It Works
                </Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              className={classes.dividerPrimary}
            >
              <Divider primaryDivider />
            </Box>
            <HowDoesItWorksDataMapped />
          </Box>
        </MarginWrapper>

        <Box mt={9} className={classes.helpBox}>
          <MarginWrapper>
            <Typography variant="h1" className={classes.headingColor}>
              <b>WE HELP YOU WRITE YOUR</b>
              <div>
                <b>DOCUMENTS!</b>
              </div>
              <Divider secondaryDivider />
            </Typography>

            <Box mt={5}>
              <Typography variant="h4">
                A Team Of Lawyers And Legal Experts <br /> Creates The Templates
              </Typography>
            </Box>
          </MarginWrapper>
        </Box>
        <MarginWrapper>
          <Box mt={9}>
            <Box className={classes.backgroundtext}>
              <Box mb={2} className={classes.textColor}>
                <Typography variant="h3">
                  SAMPLE DOCUMENT TO DOWNLOAD
                </Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              className={classes.divider}
            >
              <Divider primaryDivider />
            </Box>
            <Box className={classes.SampleDocCenter}>
              <LegalDocumnet />
              <FinancalDocument />
            </Box>
          </Box>
        </MarginWrapper>
        <Box mt={9} className={classes.helpBox} textAlign="center">
          <MarginWrapper>
            <Typography variant="h1" className={classes.headingColor}>
              <b>STAY UP-TO-DATE</b>
              <Box display="flex" justifyContent="center">
                <Divider secondaryDivider />
              </Box>
            </Typography>
            <Box mt={3} textAlign="center">
              <Typography variant="h4">
                Sign Up To Our Mailing List And Get Notified For Exclusive
                Offers And Discounts
              </Typography>
              <Box pt={6} pb={3}>
                <Box pt={2} display="flex" justifyContent="center">
                  <input
                    type="text"
                    className={classes.inputed}
                    placeholder="Enter Your Email Here"
                  />
                  <Button
                    className={classes.btns}
                    variant="outlinedmainnoborder"
                    size="large "
                  >
                    SUBSCRIBE
                  </Button>
                </Box>
              </Box>
            </Box>
          </MarginWrapper>
        </Box>
        <div className={classes.posokay}>
          <img src={axe} className={classes.axe} alt="" />
        </div>
      </div>
    </>
  );
};

export default Home;
