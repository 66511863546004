import { makeStyles } from "@material-ui/core/styles";
import img from "../../assest/background.jpg";
const useStyles = makeStyles((theme) => ({
  main: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${img})`,
    height: "700px",
    overflow: "hidden",
    position: "relative",
  },
  mainCont: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyItems: "center",
    padding: 10,
    marginBottom: "10rem",
    paddingTop: "3rem",
  },
  forMainHeading: {
    marginTop: "1rem",
    textTransform: "capitalize",
    "& p": {
      fontWeight: 700,
      color: theme.palette.text.hoverColor,
      fontSize: 30,
    },
  },
  forDelText: {
    padding: 50,
    marginTop: "1rem",
    backgroundColor: theme.palette.background.alpha,
    display: "flex",
    "& p": {
      fontWeight: "bold",
      fontSize: 20,
      textTransform: "capitalize",
    },
  },
  forBtns: {
    marginTop: "1rem",
  },
  forBtn1: {
    fontWeight: "bold",
    fontSize: 18,
    margin: 10,
  },
  forBtn2: {
    backgroundColor: theme.palette.background.btnbackground,
    fontWeight: "bold",
    fontSize: 18,
  },
}));
export default useStyles;
