import { createSlice } from "@reduxjs/toolkit";
import { editBillingAddress } from "./billingAddress.action";

const initialState = {
  isEditBillingAddressLoading: false,
  isEditBillingAddressLoadingFailed: false,
  isEditBillingAddressLoadingSuccess: false,
  message: "",
};

const BillingAddress = createSlice({
  name: "editBillingAddress",
  initialState,
  extraReducers: {
    [editBillingAddress.pending]: (state) => {
      state.isEditBillingAddressLoading = true;
      state.isEditBillingAddressLoadingSuccess = false;
      state.message = "updating address details please wait.....";
    },
    [editBillingAddress.fulfilled]: (state, action) => {
      state.isEditBillingAddressLoading = false;
      state.isEditBillingAddressLoadingSuccess = true;
      state.message = action.payload;
      state.message = "Address details updated.";
    },
    [editBillingAddress.rejected]: (state) => {
      state.isEditBillingAddressLoading = false;
      state.isEditBillingAddressLoadingFailed = true;
      state.isEditBillingAddressLoadingSuccess = false;
      state.message = "Updating Address details failed!";
    },
  },

  reducers: {
    resetAddingBillingAddressState: () => {
      return initialState;
    },
  },
});
export const { resetAddingBillingAddressState } = BillingAddress.actions;
export default BillingAddress.reducer;
