import React from "react";
import clsx from "clsx";
import { Divider as MuiDivider, makeStyles } from "@material-ui/core";
const Divider = (props) => {
  const classes = useStyles();
  const { primaryDivider, secondaryDivider } = props;
  return (
    <div
      className={clsx(classes.main, props.className, {
        [classes.primaryDivider]: primaryDivider,
        [classes.secondaryDivider]: secondaryDivider,
      })}
    >
      <MuiDivider />
    </div>
  );
};

export default Divider;

const useStyles = makeStyles((theme) => ({
  primaryDivider: {
    marginTop: 5,
    marginBottom: "40px",
    // marginLeft: "45px",
    width: "10%",
    height: "4px",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    backgroundColor: theme.palette.text.placeholder,
    [theme.breakpoints.down("xs")]: {
      width: "25%",
      marginLeft: "0px",
    },
  },
  secondaryDivider: {
    marginTop: "20px",
    width: "70px",
    height: "3px",
    backgroundColor: theme.palette.icon.main,
  },
}));
