import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  GetSingleDoc,
  getSingleFilledDocument,
  updateDocument,
} from "../../Features/getSingleDocById/getSingleDocById.action";

import DocumentViewer from "../../components/documentViewer";
import DocumentSkeleton from "../../components/documentSkeleton";

const EditDocument = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    getSingleFilledDocById: { data },
    getSingleFilledDocByIdLoading,
  } = useSelector((state) => state.getSingleDocById);

  const handleSubmit = (payload) => {
    dispatch(updateDocument({ ...payload, filledDocId: data.filledDocId }));
  };

  useEffect(() => {
    dispatch(getSingleFilledDocument(id));
  }, [dispatch, id]);

  return (
    <>
      {getSingleFilledDocByIdLoading ? (
        <DocumentSkeleton />
      ) : (
        data && (
          <DocumentViewer
            data={data.data}
            html={data.html_code}
            handleSubmit={handleSubmit}
            title={data.title}
          />
        )
      )}
    </>
  );
};

export default EditDocument;
