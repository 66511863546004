import { createSlice } from "@reduxjs/toolkit";
import {
  GetSingleDoc,
  getSingleFilledDocument,
  saveDocument,
  updateDocument,
} from "./getSingleDocById.action";
const initialState = {
  getSingleDocById: [],
  getSingleDocByIdLoading: false,
  getSingleDocByIdLoadedSucess: false,
  getSingleDocByIdLoadedFailed: false,
  /////////////////////////////
  getSingleFilledDocById: [],
  getSingleFilledDocByIdLoading: false,
  getSingleFilledDocByIdLoadedSucess: false,
  getSingleFilledDocByIdLoadedFailed: false,
  /////////////////////////////
  isDocumentSaveLoading: false,
  isDocumentSaved: false,
  isDocumentSaveFailed: false,
  saveDocumentData: {},
  /////////////////////////////
  isDocumentUpdateLoading: false,
  isDocumentUpdated: false,
  isDocumentUpdateFailed: false,
  updateDocumentData: {},
};
const getSingleDocById = createSlice({
  name: "getSingleDocById",
  initialState,
  extraReducers: {
    [GetSingleDoc.pending]: (state, action) => {
      state.getSingleDocByIdLoading = true;
      state.getSingleDocByIdLoadedFailed = false;
    },
    [GetSingleDoc.fulfilled]: (state, action) => {
      state.getSingleDocByIdLoadedSucess = true;
      state.getSingleDocByIdLoadedFailed = false;
      state.getSingleDocByIdLoading = false;
      state.getSingleDocById = action.payload;
    },
    [GetSingleDoc.rejected]: (state, action) => {
      state.getSingleDocByIdLoading = false;
      state.getSingleDocByIdLoadedFailed = true;
      state.getSingleDocByIdLoadedSucess = false;
    },
    ////////////////////
    [getSingleFilledDocument.pending]: (state, action) => {
      state.getSingleFilledDocByIdLoading = true;
    },
    [getSingleFilledDocument.fulfilled]: (state, action) => {
      state.getSingleFilledDocByIdLoadedSucess = true;
      state.getSingleFilledDocByIdLoading = false;
      state.getSingleFilledDocById = action.payload;
    },
    [getSingleFilledDocument.rejected]: (state, action) => {
      state.getSingleFilledDocByIdLoading = false;
      state.getSingleFilledDocByIdLoadedFailed = true;
    },
    ////////////////////////////////
    [saveDocument.pending]: (state) => {
      state.isDocumentSaveLoading = true;
    },
    [saveDocument.fulfilled]: (state, action) => {
      state.isDocumentSaveLoading = false;
      state.saveDocumentData = action.payload;
    },
    [saveDocument.rejected]: (state) => {
      state.isDocumentSaveLoading = false;
      state.isDocumentSaveFailed = true;
    },
    ////////////////////////////////
    [updateDocument.pending]: (state) => {
      state.isDocumentUpdateLoading = true;
      state.isDocumentSaveLoading = true;
    },
    [updateDocument.fulfilled]: (state, action) => {
      state.isDocumentUpdateLoading = false;
      state.updateDocumentData = action.payload;
      state.isDocumentSaveLoading = false;
    },
    [updateDocument.rejected]: (state) => {
      state.isDocumentUpdateLoading = false;
      state.isDocumentUpdateFailed = true;
      state.isDocumentSaveLoading = false;
    },
  },
});

export default getSingleDocById.reducer;
