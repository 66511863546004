import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  mainCont: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyItems: "center",
    padding: 10,
    marginBottom: "20rem",
    marginTop: "2rem",
  },
  forMainHeading: {
    marginTop: "1rem",
    textTransform: "capitalize",
    "& p": {
      fontWeight: 700,
      color: theme.palette.text.hoverColor,
      fontSize: 30,
    },
  },
  forDelText: {
    padding: 50,
    marginTop: "1rem",
    backgroundColor: theme.palette.background.alpha,
    display: "flex",
    "& p": {
      fontWeight: "bold",
      fontSize: 20,
      textTransform: "capitalize",
    },
  },
  forBtns: {
    marginTop: "1rem",
  },
  forBtn1: {
    fontWeight: "bold",
    fontSize: 18,
    margin: 10,
  },
  forBtn2: {
    backgroundColor: theme.palette.background.btnbackground,
    fontWeight: "bold",
    fontSize: 18,
  },
}));
export default useStyles;