import { createSlice } from "@reduxjs/toolkit";
import { AllFlags } from "./allflag.action";
const initialState = {
  countriesFlags: [],
  countriesFlagsLoading: false,
  countriesFlagsLoadedSucess: false,
  countriesFlagsLoadedFailed: false,
};
const allcountriesFlag = createSlice({
  name: "countriesflags",
  initialState,
  extraReducers: {
    [AllFlags.pending]: (state, action) => {
      state.countriesFlagsLoading = true;
    },
    [AllFlags.fulfilled]: (state, action) => {
      state.countriesFlagsLoadedSucess = true;
      state.countriesFlagsLoadedFailed = false;
      state.countriesFlags = action.payload;
    },
    [AllFlags.failed]: (state, action) => {
      state.countriesFlagsLoading = false;
      state.countriesFlagsLoadedFailed = true;
    },
  },
});

export default allcountriesFlag.reducer;
