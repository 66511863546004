import React from "react";

import { Box, makeStyles, Typography } from "@material-ui/core";

const HowDoesItWorks = ({ value, image, flexColumn, title, description }) => {
  const classes = useStyles();
  return (
    <Box className={classes.container} m={1} display={flexColumn && "flex"}>
      <div>
        <Typography className={classes.serialNum} variant="h3">
          {value}
        </Typography>
        <Box>
          <img className={classes.image} src={image} alt="" />
        </Box>
      </div>

      <Box mt={4} className={classes.text}>
        <Box>
          <Typography variant="h3">
            <b>{title}</b>
          </Typography>
        </Box>
        <Box mt={3}>
          <Typography
            variant="h4"
            className={classes.description}
            dangerouslySetInnerHTML={{ __html: description }}
          ></Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default HowDoesItWorks;

const useStyles = makeStyles((theme) => ({
  container: { maxWidth: "100%" },
  text: { color: "#3E3E3E", width: "25ch" },
  serialNum: { color: theme.palette.icon.main, margin: 10 },
  image: { width: 152, height: 116, objectFit: "contain" },
}));
