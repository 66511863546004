import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import YouTubeIcon from "@material-ui/icons/YouTube";
import { makeStyles } from "@material-ui/core/styles";
import Flags from "../CountrySlector/index";
import MarginWrapper from "../marginwrapper";

const Footer = () => {
  const classes = useStyles();
  return (
    <Box className={classes.footer_main_container}>
      <MarginWrapper>
        <Box className={classes.footer}>
          <Box className={classes.footer_Column} color="secondary">
            <Link to="/sitemap">
              <Typography variant="h6" className={classes.footerlinkstext}>
                Site Map
              </Typography>
            </Link>
            <Link to="/legalguidance">
              <Typography variant="h6" className={classes.footerlinkstext}>
                Legal Guides
              </Typography>
            </Link>
            <Link to="/">
              <Typography variant="h6" className={classes.footerlinkstext}>
                Legal Notice
              </Typography>
            </Link>
            <Link to="/TermsAndCondition">
              <Typography variant="h6" className={classes.footerlinkstext}>
                Terms and Conditions
              </Typography>
            </Link>
          </Box>
          <Box className={classes.footer_Column}>
            <Link to="/">
              <Typography variant="h6" className={classes.footerlinkstext}>
                FAQs
              </Typography>
            </Link>
            <Link to="/auth/sign-up">
              <Typography variant="h6" className={classes.footerlinkstext}>
                Account
              </Typography>
            </Link>
            <Link to="/contactus">
              <Typography variant="h6" className={classes.footerlinkstext}>
                Contact
              </Typography>
            </Link>
            <Link to="/viewall">
              <Typography variant="h6" className={classes.footerlinkstext}>
                Documents
              </Typography>
            </Link>
          </Box>
          <Box className={classes.footer_Column} mb={5}>
            <Typography variant="h4" className={classes.footerlinkstext}>
              CHOOSE YOUR COUNTRY
            </Typography>
            <Flags height={68} />
          </Box>
        </Box>
        <Box className={classes.footer_icon_div}>
          <Typography variant="h4">Follow us</Typography>
          <Box className={classes.footer_iconGroup}>
            <IconButton className={classes.social_icon}>
              <InstagramIcon />
            </IconButton>
            <IconButton className={classes.social_icon}>
              <FacebookIcon />
            </IconButton>
            <IconButton className={classes.social_icon}>
              <TwitterIcon />
            </IconButton>
            <IconButton className={classes.social_icon}>
              <YouTubeIcon />
            </IconButton>
          </Box>
        </Box>
      </MarginWrapper>
    </Box>
  );
};

export default Footer;

const useStyles = makeStyles((theme) => ({
  footer_main_container: {
    background: "#313131",
    color: theme.palette.secondary.main,
  },

  footer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "54px 0px",
    borderBottom: "1px #666666 solid",
    "& a": {
      color: theme.palette.secondary.main,
    },
    [theme.breakpoints.down("sm")]: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gap: "30px",
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
    },
  },
  footer_Column: {
    "& h4": {
      fontWeight: "700",
    },
    "& h5": {
      marginBottom: "11px",
    },
    "& a": {
      textDecoration: "none",
    },
  },
  footer_icon_div: {
    padding: "18px 30px 30px 30px",
    textAlign: "center",
    "& h4": {
      fontWeight: "700",
      marginBottom: "10px",
    },
    "& button": {
      backgroundColor: "#525067",
      color: theme.palette.secondary.main,

      "& svg": {
        fontSize: "20px",
      },
    },
    [theme.breakpoints.down("md")]: {
      paddingTop: "30px",
      paddingBottom: "50px",
    },
  },
  footer_iconGroup: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "24px",
  },
  footerlinkstext: {
    padding: "5px 0px",
    fontSize: 14,
    fontWeight: 600,

    "&:hover": {
      color: "#BC802B",
    },
  },
  social_icon: {
    "&:hover": {
      background: "#3FA7DA",
    },
  },
}));
