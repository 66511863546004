import React from "react";
import data from "./data";
import HowDoesItWorks from "../cards/howDoesItWorks";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const HowDoesItWorksDataMapped = (props) => {
  const classes = useStyles();
  const { flexColumn } = props;
  return (
    <div
      className={clsx(classes.main, props.className, {
        [classes.flexColumn]: flexColumn,
      })}
    >
      {data.map(({ id, image, title, description }) => {
        return (
          <HowDoesItWorks
            flexColumn={flexColumn}
            value={id}
            image={image}
            title={title}
            description={description}
          />
        );
      })}
    </div>
  );
};

export default HowDoesItWorksDataMapped;

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",

    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
      justifyContent: "center",
    },
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
}));
