import { createAsyncThunk } from "@reduxjs/toolkit";
import { postPaymentApi } from "./apis";

export const postPayment = createAsyncThunk(
  "payment/postPayment",
  async (payload) => {
    const res = await postPaymentApi(payload);
    return res.data;
  }
);
