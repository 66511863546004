import { Box, makeStyles } from "@material-ui/core";
import React from "react";
import MarginWrapper from "../marginwrapper";

function FormFillHead({ children }) {
  const classes = useStyles();
  return (
    <Box className={classes.documentMainHeadSection}>
      <MarginWrapper>
        <Box className="SearchBar">{children}</Box>
      </MarginWrapper>
    </Box>
  );
}

export default FormFillHead;
const useStyles = makeStyles((theme) => ({
  documentMainHeadSection: {
    opacity: 0.7,
    padding: "20px 0px 0px 0px",
    fontFamily: theme.fontFamily,
    background: theme.palette.background.banner,
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },

    "& .SearchBar": {
      padding: "70px 0px  80px 0px",
      [theme.breakpoints.down("md")]: {
        padding: "40px 10px  80px 0px",
        justifyContent: "center",
      },
    },
  },
}));
