import axios from "axios";
import { legalFinancal } from "../HTTP/urls";

legalFinancal.interceptors.request.use(
  (config) => {
    const JWT = localStorage.getItem("legalJWTtoken");
    if (JWT) config.headers.Authorization = `Bearer ${JWT}`;
    return config;
  },
  (error) => Promise.reject(error)
);

const apiService = {
  async get(url, { params }) {
    try {
      const res = await legalFinancal.get(url, {
        params,
      });
      return res;
    } catch (reason) {
      return await Promise.reject(reason);
    }
  },

  async post(url, data) {
    return legalFinancal
      .post(url, data)
      .then((res) => res)
      .catch((reason) => Promise.reject(reason));
  },

  awaitAll() {
    return legalFinancal
      .all(Array.from(arguments))
      .then(axios.spread((...responses) => responses))
      .catch((reasons) => Promise.reject(reasons));
  },
};

export default apiService;
