import { legalFinancal } from "./urls";

export const signUp = async (payload) => {
  const res = await legalFinancal.get("/api/users/singUp", {
    params: {
      id: payload,
    },
  });
  return res;
};

export const getAllFlag = async () => {
  const res = await legalFinancal.get("api/users/fetchAllCountries");

  return res;
};

export const getDocById = async ({ docId, pageNo }) => {
  const res = await legalFinancal.get(
    `api/users/fetchAllDocByCountry/${docId}`,
    {
      params: {
        pageNo: pageNo,
      },
    }
  );

  return res;
};
export const getDocByTitle = async ({ title, pageNo }) => {
  const res = await legalFinancal.get(`api/users/searchDocument/${title}`);

  return res;
};
export const getSingleDocById = async (id) => {
  const res = await legalFinancal.get(`api/users/fetchSingleDoc/${id}`);

  return res;
};

export const getSearch = async (payload) => {
  const res = await legalFinancal.get(`/api/users/searchDocument/${payload}`);

  return res;
};

export const getSingleFilledDocumentApi = async (payload) => {
  const res = await legalFinancal.get(
    `/api/users/fetchSingleFilledDoc/${payload}`
  );

  return res;
};

export const saveDocumentApi = async (payload) => {
  const res = await legalFinancal.post("/api/users/saveFillDocument", payload);

  return res;
};
export const updateDocumentApi = async (payload) => {
  const res = await legalFinancal.post("/api/users/updateDocument", payload);

  return res;
};

export const getAllFilledDocumentsApi = async (payload) => {
  const res = await legalFinancal.get(
    `/api/users/fetchAllFilledDocByUser/${payload}`
  );

  return res;
};
