import { makeStyles } from "@material-ui/core";
import img from "../../assest/background2.jpg";
const useStyles = makeStyles((theme) => ({
  main: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${img})`,
    backgroundPosition: "center",
    position: "relative",
    overflow: "hidden",
  },
  body_grid: {
    display: "flex",
    justifyContent: "center",
    gridGap: 20,
    [theme.breakpoints.down("sm")]: {
      padding: " 0px  0px 0px 0px",
      flexDirection: "column-reverse",
    },
    [theme.breakpoints.down("xs")]: {
      padding: 0,
      flexDirection: "column-reverse",
    },
  },
}));
export default useStyles;
