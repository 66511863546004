import { Typography, Button, CircularProgress } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Link } from "react-router-dom";
import { Box } from "@material-ui/core";
import SearchBar from "../SearchBar/SearchBar";
import useStyles from "./Style";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { GetDoc } from "../../Features/getDocByCountry/getDocByCountry.action";
import LoadingCompo from "../loadingCompo";
import DescriptionIcon from "@material-ui/icons/Description";

const LegalDocumnet = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [legalText, setLegalText] = useState();

  const handleLegalTextChange = (e) => {
    setLegalText(e.target.value);
  };

  const {
    getDocByCountry: {
      getDocByCountryLoading,
      getDocByCountryLoadedSucess,
      getDocByCountry,
    },
  } = useSelector((state) => state);

  useEffect(() => {
    const payload = { docId: 1, pageNo: 1 };
    dispatch(GetDoc(payload));
  }, [dispatch]);

  return (
    <div className={classes.LegalDocContainer}>
      <div className={classes.header}>
        <Typography variant="h4">
          <b>LEGAL DOCUMENTS</b>
        </Typography>
      </div>
      <div className={classes.search}>
        <SearchBar searchLil searchDoc change={handleLegalTextChange} />
      </div>
      <div className={classes.mapData}>
        {getDocByCountryLoading && (
          <div>
            <LoadingCompo />
          </div>
        )}

        {getDocByCountryLoadedSucess &&
          getDocByCountry
            ?.filter((item) => {
              if (!legalText) return item;
              const itemString = String(item.description);
              return itemString
                .toLowerCase()
                .startsWith(legalText.toLowerCase());
            })
            .map((items) => {
              return (
                <Link to={`/document/${items.id}`}>
                  <Box pt={1} className={classes.Content}>
                    <DescriptionIcon
                      style={{ padding: "0px", color: "#E29537" }}
                    />{" "}
                    <Typography h5 className={classes.ContentItem}>
                      {items.title}
                    </Typography>
                  </Box>
                </Link>
              );
            })}
        <Link to="/viewall">
          <div className={classes.ViewAllBtn}>
            <Button className="button" endIcon={<ArrowForwardIcon />}>
              View All
            </Button>
          </div>
        </Link>
      </div>
    </div>
  );
};
export default LegalDocumnet;
