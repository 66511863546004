import React, { useState } from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import InputField from "../inputfeild";
import Button from "../buttons";
import { Link } from "react-router-dom";
export default function AddSingerModal(props) {
  const classes = useStyles();
  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <div className={classes.paper}>
            <Box className={classes.inputFieldContainer}>
              <Box className={classes.inputField} pt={2} pl={2} pr={2}>
                <Typography className={classes.labelText} variant="h6">
                  Enter your full name:
                </Typography>

                <InputField variant="outlined" className={classes.inputfeild} />
              </Box>
              <Box className={classes.inputField} pt={2} pl={2} pr={2}>
                <Typography className={classes.labelText} variant="h6">
                  Your email address:
                </Typography>

                <InputField variant="outlined" className={classes.inputfeild} />
              </Box>
            </Box>

            <Box mt={3} display="flex" justifyContent="center">
              <Button variant="outlined">
                <Typography
                  variant="h6"
                  className={classes.modalButton}
                  onClick={handleClose}
                >
                  cancel
                </Typography>
              </Button>
              <Box ml={1}>
                <Link to="/modifysignature">
                  <Button variant="outlinedmain">
                    <Typography variant="h6" className={classes.modalButton}>
                      save new signer
                    </Typography>
                  </Button>
                </Link>
              </Box>
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 4,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: 867,
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      width: "400px",
    },
  },
  inputFieldContainer: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  labelText: {
    textTransform: "capitalize",
    fontWeight: "bold",
    color: theme.palette.text.primary,
  },
  inputField: {
    width: "75%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  clearButton: {
    border: "none",
    backgroundColor: "transparent",

    cursor: "pointer",
  },
  signatureCanvas: {
    maxWidth: 500,
    maxWheight: 190,
    border: `1px solid ${theme.palette.background.primary}`,
    [theme.breakpoints.down("xs")]: {
      width: "310px",
    },
  },
  modalButton: { fontWeight: "bold" },
  inputfeild: {
    border: "none",
    "& .MuiOutlinedInput-input": {
      padding: 7,
    },
  },
}));
