import { legalFinancal } from "../../HTTP/urls";

export const getDocumentsByCategoryApi = async () => {
  try {
    const res = await legalFinancal.get(
      "/api/users/fetchAllDocsWithCategories"
    );
    return res;
  } catch (error) {
    throw error;
  }
};
