import { createSlice } from "@reduxjs/toolkit";
import { GetDoc } from "./getDocByCountry.action";
const initialState = {
  getDocByCountry: [],
  getDocByCountryLoading: false,
  getDocByCountryLoadedSucess: false,
  getDocByCountryLoadedFailed: false,
};
const GetDocByCountry = createSlice({
  name: "getDocByCountry",
  initialState,
  extraReducers: {
    [GetDoc.pending]: (state, action) => {
      state.getDocByCountryLoading = true;
      state.getDocByCountryLoadedFailed = false;
    },
    [GetDoc.fulfilled]: (state, action) => {
      state.getDocByCountryLoadedSucess = true;
      state.getDocByCountryLoadedFailed = false;
      state.getDocByCountryLoading = false;
      state.getDocByCountry = action.payload;
    },
    [GetDoc.failed]: (state, action) => {
      state.getDocByCountryLoading = false;
      state.getDocByCountryLoadedFailed = true;
      state.getDocByCountryLoadedSucess = false;
    },
  },
});

export default GetDocByCountry.reducer;
