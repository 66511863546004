import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getSingleDocById,
  getSingleFilledDocumentApi,
  saveDocumentApi,
  updateDocumentApi,
} from "../../HTTP/api";

export const GetSingleDoc = createAsyncThunk(
  "getSingleDocById",
  async (payload) => {
    const res = await getSingleDocById(payload);

    return res.data;
  }
);

export const getSingleFilledDocument = createAsyncThunk(
  "getSingleFilledDocument",
  async (payload) => {
    const res = await getSingleFilledDocumentApi(payload);

    return res.data;
  }
);

export const saveDocument = createAsyncThunk(
  "document/saveDocument",
  async (payload) => {
    const res = await saveDocumentApi(payload);
    return res.data;
  }
);

export const updateDocument = createAsyncThunk(
  "document/updateDocument",
  async (payload) => {
    const res = await updateDocumentApi(payload);
    return res.data;
  }
);
