import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: "0px 250px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      padding: "0px 190px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0px 70px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0px 20px",
    },
  },
}));

const MarginWrapper = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.wrapper}>{children}</div>;
};

export default MarginWrapper;
