import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  LegalDocContainer: {
    width: "400px",
    borderRadius: "10px",
    marginTop: "10px",
    zIndex: 10,
  },
  header: {
    backgroundColor: theme.palette.background.main,
    height: "70px",
    display: "flex",
    paddingLeft: "35px",
    borderRadius: "10px 10px 0px 0px",
    alignItems: "center",
  },
  mapData: {
    backgroundColor: "#EBF6FE",
  },
  SearchDoc: {
    backgroundColor: "white",
    border: "none",
    borderRadius: "10px",
    height: "40px",
    padding: "10px",
    width: "290px",
    textAlign: "center",
    paddingTop: "10px",
    outline: "none",
  },
  Content: {
    display: "flex",
    justifyContent: "flex-start",
    backgroundColor: theme.palette.background.bluebackground,
    paddingLeft: "10px",
  },
  ContentItem: {
    lineHeight: "30px",
    color: theme.palette.text.primary,
    display: "flex",
    fontWeight: 400,
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.main.primary,
    },
  },
  search: {
    backgroundColor: theme.palette.background.bluebackground,
    padding: "25px",
  },
  AlignItems: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.background.bluebackground,

    position: "relative",
    paddingTop: "10px",
    padding: "30px",
  },
  SearchIcon: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    backgroundColor: theme.palette.background.primary,
    height: "33px",
    width: "35px",
    position: "absolute",
    left: "34px",
    borderRadius: "10px",
  },
  ViewAllBtn: {
    display: "flex",
    justifyContent: "flex-end",
    backgroundColor: theme.palette.background.bluebackground,
    fontWeight: 700,
    paddingBottom: "25px",
    paddingTop: "30px",
    borderRadius: 10,
    alignItems: "center",
    paddingRight: "20px",
    "& .button": {
      color: theme.palette.text.primary,

      transition: "none",
    },
    "& :hover": {
      color: theme.palette.secondary.main,
      background: theme.palette.icon.main,
    },
  },
  button: {
    color: theme.palette.text.primary,
    borderRadius: 5,
    transition: "none",
  },
}));
export default useStyles;
