import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../../components/checkoutForm";

// Create the Stripe object yourself...
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
const StripePayment = () => (
  // ...and pass it directly to <Elements>.

  <Elements stripe={stripePromise}>
    <CheckoutForm />
  </Elements>
);
export default StripePayment;
