import { createSlice } from "@reduxjs/toolkit";
import { getAllFilledDocuments } from "./filledDocument.action";

const initialState = {
  areFilledDocumentsLoading: false,
  areFilledDocumentsLoaded: false,
  areFilledDocumentsLoadingFailed: false,
  filledDocuments: {
    totalPage: 0,
    currentPage: 0,
    data: [],
  },
};

export const filledDocuments = createSlice({
  name: "filledDocuments",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAllFilledDocuments.pending, (state) => {
      state.areFilledDocumentsLoading = true;
    });
    builder.addCase(getAllFilledDocuments.fulfilled, (state, action) => {
      state.areFilledDocumentsLoaded = true;
      state.filledDocuments = { ...action.payload };
      state.areFilledDocumentsLoading = false;
    });
    builder.addCase(getAllFilledDocuments.rejected, (state) => {
      state.areFilledDocumentsLoadingFailed = true;
      state.areFilledDocumentsLoading = false;
    });
  },
});

export default filledDocuments.reducer;
