import { Helmet, HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import RootRouter from "./routes";
import store from "./redux/store";
import HooksWrapper from "./hooksWrapper";

const App = () => {
  return (
    <HelmetProvider>
      <Helmet
        titleTemplate="%s | legal-and-financial"
        defaultTitle="legal-and-financial"
      />
      <Provider store={store}>
        <HooksWrapper>
          <RootRouter />
        </HooksWrapper>
      </Provider>
    </HelmetProvider>
  );
};

export default App;
