import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  GetSingleDoc,
  saveDocument,
  updateDocument,
} from "../../Features/getSingleDocById/getSingleDocById.action";

import DocumentViewer from "../../components/documentViewer";
import DocumentSkeleton from "../../components/documentSkeleton";

const DocumentRoute = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    getSingleDocByIdLoading,
    getSingleDocByIdLoadedSucess,
    getSingleDocById,
    saveDocumentData,
  } = useSelector((state) => state.getSingleDocById);

  const handleSubmit = (payload) => {
    if (!saveDocumentData.id) {
      dispatch(saveDocument({ ...payload }));
    } else {
      dispatch(
        dispatch(
          updateDocument({ ...payload, filledDocId: saveDocumentData.id })
        )
      );
    }
  };

  // Call Api With get Single Doc Using Id
  useEffect(() => {
    dispatch(GetSingleDoc(id));
  }, [dispatch, id]);

  return (
    <>
      {getSingleDocByIdLoading ? (
        <DocumentSkeleton />
      ) : (
        getSingleDocById && (
          <DocumentViewer
            title={getSingleDocById.title}
            data={getSingleDocById.data}
            html={getSingleDocById.html_code}
            handleSubmit={handleSubmit}
          />
        )
      )}
    </>
  );
};

export default DocumentRoute;
