import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import Button from "../../components/buttons";
import Signature from "../../assest/images/Vector.png";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import changeIcon from "../../assest/images/change.png";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import AddSingerModal from "../addSingerModal";
function ShowSignatureForm() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Box className={classes.main_container}>
      <Box className={classes.main_inner_container}>
        <Box p={1}>
          <Typography variant="h6">
            Drag and drop your signature into the document.
          </Typography>
        </Box>
        <Box
          className={classes.signatureBox}
          textAlign="center"
          display="flex"
          justifyContent="center"
        >
          <Button
            className={classes.signatureButton}
            variant="outlined"
            startIcon={<DragIndicatorIcon className={classes.signatureIcon} />}
          >
            <Box className={classes.signaturePicture}>
              <img src={Signature} alt="" />
            </Box>
          </Button>
        </Box>
        <Box display="flex" mt={1} ml={4} className={classes.subtitle}>
          <img src={changeIcon} alt="" />
          <Box display="flex" alignItems="center" pl={0.5}>
            <small>Change your signature</small>
          </Box>
        </Box>

        <Box ml={1} mt={5}>
          <Typography variant="h3">Add a new signer</Typography>
        </Box>
        <Box mt={1} textAlign="center">
          {" "}
          <Button
            className={classes.addpersonButton}
            variant="outlined"
            onClick={handleOpen}
          >
            <PersonAddIcon fontSize="large" className={classes.signatureIcon} />
            <Box pl={1}>
              <Typography variant="h6">Add a new person</Typography>
            </Box>
          </Button>
          <AddSingerModal open={open} setOpen={setOpen} />
        </Box>
        <Box mt={4} textAlign="center">
          {" "}
          <Button variant="outlinedSecondary">
            <Typography className={classes.confirmButton} variant="h6">
              Confirm and continue
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default ShowSignatureForm;
const useStyles = makeStyles((theme) => ({
  main_container: {
    position: "relative",
    top: "-60px",
    width: 300,
    color: theme.palette.text.primary,
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
      width: "100%",
    },
  },
  main_inner_container: {
    width: 300,
    boxShadow: "rgba(17, 17, 26, 0.1) 0px 0px 16px",
    background: theme.palette.text.secondary,
    padding: 13,

    height: "350px",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      width: "100%",
    },
  },

  signatureIcon: {
    color: theme.palette.main.primary,
    fontSize: "25px !important",
  },
  signaturePicture: {
    width: "70%",
    border: "1px solid " + theme.palette.main.primary,
    height: "42px",
    display: "flex",
    justifyContent: "center",
  },
  signatureButton: {
    textAlign: "initial",
    height: "43px",
    textTransform: "capitalize",
    fontSize: 14,
    width: "95%",
    display: "flex",
    justifyContent: "flex-start",
    "&::before": {
      content: "''",
      position: "absolute",
      background: theme.palette.main.primary,
      width: 5,
      height: "80%",
      lefdisplay: "flex",
      alignItems: "center",
      left: 0,
      borderRadius: 5,
    },
    "&:hover": {
      background: theme.palette.background.inputBg,
    },
  },
  subtitle: {
    fontFamily: ["Manrope", "sans-serif"],
    fontSize: 12,

    cursor: "pointer",
  },
  addpersonButton: {
    width: "95%",
    color: theme.palette.background.primary,
    justifyContent: "flex-start",
  },
  confirmButton: {
    fontsize: 18,
    fontWeight: 800,
  },
  signatureBox: {
    position: "relative",
  },
}));
