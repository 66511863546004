import { legalFinancal } from "../../HTTP/urls";

export const postLogIn = async (payload) => {
  const res = await legalFinancal.post("api/users/login", payload, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
  });
  return res.data;
};

export const checkSessionAPI = async () => {
  const res = await legalFinancal.get("api/users/checkSession", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("legalJWTtoken")}`,
    },
  });
  return res.data;
};
