import { Box, Fade, Modal, Paper, Typography } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Button from "../buttons";
import InputField from "../inputfeild";
import { makeStyles } from "@material-ui/styles";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Alert } from "@material-ui/lab";
import { addLogIn } from "../../Features/signIn/signIn.action";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  resetRegisteringUser,
  toogleLoginModal,
  toogleSigninModal,
} from "../../Features/signIn/signIn.slicer";
import LoadingCompo from "../loadingCompo";
import CancelIcon from "@material-ui/icons/Cancel";

const SignupSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().required("This field is required"),
});

const SignIn = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loginLoadingSucess, loginLoading, showLoginModal } = useSelector(
    (state) => state.LoginSlicer
  );
  const { login } = useSelector((state) => state.LoginSlicer);
  const { error } = useSelector((state) => state.LoginSlicer);
  console.log(login);
  if (loginLoadingSucess && login) {
    if (login?.token) {
      localStorage.setItem("legalJWTtoken", login?.token);
    }
    setTimeout(() => {
      console.log(handleModalClose);
      dispatch(handleModalClose());
    }, 2000);
  }

  const handleModalClose = () => {
    dispatch(toogleLoginModal(false));
  };

  const handleNavigateToSignIn = () => {
    handleModalClose();
    dispatch(toogleSigninModal(true));
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={showLoginModal}
      // onClose={handleClose}
      closeAfterTransition
      // BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={showLoginModal}>
        <Paper elevation={3} className={classes.paperContainer}>
          <Box>
            <CancelIcon
              className={classes.closeIcon}
              onClick={handleModalClose}
            />
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Typography variant="h5">
                <b>Sign In</b>
              </Typography>
            </Box>
          </Box>
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={SignupSchema}
            onSubmit={(values) => {
              // same shape as initial values
              const payload = {
                email: values.email,
                password: values.password,
              };

              // send to server

              dispatch(addLogIn(payload));
            }}
          >
            {({
              errors,
              touched,
              values,
              handleSubmit,
              handleChange,
              handleBlur,
            }) => (
              <Form>
                {loginLoadingSucess ? (
                  <>
                    {" "}
                    {error ? (
                      <Alert severity="error">{error}</Alert>
                    ) : (
                      <Alert severity="success">Log In success</Alert>
                    )}
                  </>
                ) : null}

                <br />

                <Box>
                  <InputField
                    variant="outlined"
                    placeholder="Email"
                    type="email"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                  />
                  {errors.email && touched.email ? (
                    <Typography>{errors.email}</Typography>
                  ) : null}
                </Box>
                <Box pt={2}>
                  <InputField
                    variant="outlined"
                    placeholder="Password"
                    type="password"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                  />
                  {errors.password && touched.password ? (
                    <Typography>{errors.password}</Typography>
                  ) : null}
                </Box>

                <Box pt={2} display="flex" justifyContent="center">
                  <Button
                    className={classes.btn}
                    variant="outlinedmain"
                    size="large"
                    type="submit"
                  >
                    {loginLoading ? <LoadingCompo /> : "Login"}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
          <Box mt={1}>
            <Typography
              className={classes.typoPassword}
              component={Link}
              to="/auth/password-reset"
            >
              forgot password?
            </Typography>
          </Box>
          <Box pt={5}></Box>
          <Box display="flex" justifyContent="space-between">
            <Box onClick={handleNavigateToSignIn}>
              <Typography style={{ color: "#3E3E3E" }} variant="h5">
                <b>I want to create an acount</b>
              </Typography>
            </Box>
            <Box>
              <ArrowDropDownIcon fontSize="large" cursor="pointer" />
            </Box>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default SignIn;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  closeIcon: {
    position: "absolute",
    top: "3px",
    right: "3px",
  },

  paperContainer: {
    padding: "80px",
    width: "45%",

    position: "relative",
    height: "445px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
      padding: "40px",
    },
  },
  textField: {
    width: "100%",
  },
  typoPassword: {
    display: "flex",
    justifyContent: "end",
    color: "#3FA7DA",
  },
  btn: {
    width: "200px",
    fontSize: "18px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));
