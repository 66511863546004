import { Box, Fade, Modal, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Button from "../buttons";
import InputField from "../inputfeild";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { addSignUp } from "../../Features/signUp/signUp.action";
import { Alert } from "@material-ui/lab";
import { useEffect } from "react";
import { resetSignUpSlicer } from "../../Features/signUp/signUp.slicer";
import LoadingCompo from "../loadingCompo";
import CancelIcon from '@material-ui/icons/Cancel';
import { toogleLoginModal, toogleSigninModal } from "../../Features/signIn/signIn.slicer";

const SignupSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().required("This field is required"),
  confirmPassword: Yup.string().when("password", {
    is: (val) => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf(
      [Yup.ref("password")],
      "Both password need to be the same"
    ),
  }),
});

const SignUp = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { signUpLoading, signUpLoadingSucess, signUpLoadingFailed, error } =
    useSelector((state) => state.SignUp);

    const { showSignInModal } = useSelector(
      (state) => state.LoginSlicer
    );

  useEffect(() => {
    if (signUpLoadingSucess) {
      setTimeout(() => {
        if (signUpLoadingSucess) {
          dispatch(toogleLoginModal(true))
          dispatch(resetSignUpSlicer());
          dispatch(handleModalClose());
         
        }
      }, 2000);
    }
  }, [dispatch, signUpLoadingSucess, navigate]);

  const handleModalClose=()=>{
    dispatch(toogleSigninModal(false))
  }

  return (
    <>
      <Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    className={classes.modal}
    open={showSignInModal}
    // onClose={handleClose}
    closeAfterTransition
    // BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500,
    }}
  >
    <Fade in={showSignInModal}>
    
    
      <Paper elevation={3} className={classes.paperContainer}>
        <Box>
          <CancelIcon  className={classes.closeIcon} onClick={handleModalClose}/>
        </Box>
     
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Typography
                style={{ color: "#3E3E3E" }}
                component={Link}
                to="/auth/sign-in"
                variant="h5"
              >
                <b>Sign Up</b>
              </Typography>
            </Box>
          </Box>
          <br />

          <Box display="flex" justifyContent="space-between">
            <Box>
              <Typography variant="h5">
                <b>Create an acount</b>
              </Typography>
            </Box>
          </Box>
          <Formik
            initialValues={{
              email: "",
              password: "",
              confirmPassword: "",
            }}
            validationSchema={SignupSchema}
            onSubmit={(values) => {
              // same shape as initial values
              const payload = {
                email: values.email,
                password: values.password,
              };

              // send to server
              dispatch(resetSignUpSlicer());
              dispatch(addSignUp(payload));
            }}
          >
            {({
              errors,
              touched,
              values,
              handleSubmit,
              handleChange,
              handleBlur,
            }) => (
              <Form>
                {signUpLoadingSucess && (
                  <Alert severity="success">Sign Up success</Alert>
                )}
                <br />
                <br />
                <Box>
                  <InputField
                    variant="outlined"
                    placeholder="Email"
                    type="email"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                  />
                  {errors.email && touched.email ? (
                    <Typography>{errors.email}</Typography>
                  ) : null}
                </Box>
                <Box pt={2}>
                  <InputField
                    variant="outlined"
                    placeholder="Password"
                    type="password"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                  />
                  {errors.password && touched.password ? (
                    <Typography>{errors.password}</Typography>
                  ) : null}
                </Box>
                <Box pt={2}>
                  <InputField
                    variant="outlined"
                    placeholder="Confrim password"
                    name="confirmPassword"
                    type="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.confirmPassword}
                  />
                  {errors.confirmPassword && touched.confirmPassword ? (
                    <Typography>{errors.confirmPassword}</Typography>
                  ) : null}
                </Box>

                <Box
                  pt={2}
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                >
                  <Button
                    className={classes.btn}
                    variant="outlinedmain"
                    size="large"
                    type="submit"
                  >
                    {signUpLoading ? <LoadingCompo /> : " Create an acount"}
                  </Button>
                  {signUpLoadingFailed && (
                    <Typography style={{ marginTop: "10px", color: "red" }}>
                      {error}
                    </Typography>
                  )}
                </Box>
              </Form>
            )}
          </Formik>
        </Paper>
      </Fade>
      </Modal>
    </>
  );
};

export default SignUp;
const useStyles = makeStyles((theme) => ({

  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperContainer: {
    padding:"80px",
    width: "45%",
    position:"relative",
    minHeight: "427px",
    [theme.breakpoints.down("md")]: {
      width: "60%",
      padding: "100px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      padding: "50px",
    },
  },
  closeIcon:{
    position:"absolute",
    top:"3px",
    right:"3px",
      },
  textField: {
    width: "100%",
  },
  typoPassword: {
    textAlign: "end",
    color: "blue",
  },
  btn: {
    width: "50%",
    fontSize: "18px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));
