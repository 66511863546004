import React from "react";
import { Box, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import Divider from "@material-ui/core/Divider";

import useStyles from "./style";

const SiteMap = () => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.upperone}></Box>
      <Box style={{ backgroundColor: "#FAFAFA" }}>
        <Box className={classes.main}>
          <Box className={classes.outercont}>
            <Typography className={classes.heading}> bermuda </Typography>
            <Box className={classes.forLinks}>
              <Link to="/home">
                <ul>
                  <li>Home</li>
                </ul>
              </Link>
              <Link to="/documents">
                <ul>
                  <li>All documents</li>
                </ul>
              </Link>
              <Link to="/">
                <ul>
                  <li>legal guides</li>
                </ul>
              </Link>
              <Link to="/myaccount">
                <ul>
                  <li>account</li>
                </ul>
              </Link>
              <Link to="/FAQs">
                <ul>
                  <li>FAQ</li>
                </ul>
              </Link>
              <Link to="/">
                <ul>
                  <li>contact</li>
                </ul>
              </Link>
              <Link to="/">
                <ul>
                  <li>term and conditions</li>
                </ul>
              </Link>
            </Box>
            <Divider className={classes.divider} />
          </Box>
          <Box className={classes.outercont}>
            <Typography className={classes.heading}> brazil </Typography>
            <Box className={classes.forLinks}>
              <Link to="/">
                <ul>
                  <li>Home</li>
                </ul>
              </Link>
              <Link to="/">
                <ul>
                  <li>All documents</li>
                </ul>
              </Link>
              <Link to="/">
                <ul>
                  <li>legal guides</li>
                </ul>
              </Link>
              <Link to="/">
                <ul>
                  <li>account</li>
                </ul>
              </Link>
              <Link to="/">
                <ul>
                  <li>FAQ</li>
                </ul>
              </Link>
              <Link to="/">
                <ul>
                  <li>contact</li>
                </ul>
              </Link>
              <Link to="/">
                <ul>
                  <li>term and conditions</li>
                </ul>
              </Link>
            </Box>
            <Divider className={classes.divider} />
          </Box>
          <Box className={classes.outercont}>
            <Typography className={classes.heading}> bahamas </Typography>
            <Box className={classes.forLinks}>
              <Link to="/home">
                <ul>
                  <li>Home</li>
                </ul>
              </Link>
              <Link to="/documents">
                <ul>
                  <li>All documents</li>
                </ul>
              </Link>
              <Link to="/">
                <ul>
                  <li>legal guides</li>
                </ul>
              </Link>
              <Link to="/myaccount">
                <ul>
                  <li>account</li>
                </ul>
              </Link>
              <Link to="/FAQs">
                <ul>
                  <li>FAQ</li>
                </ul>
              </Link>
              <Link to="/">
                <ul>
                  <li>contact</li>
                </ul>
              </Link>
              <Link to="/">
                <ul>
                  <li>term and conditions</li>
                </ul>
              </Link>
            </Box>
            <Divider className={classes.divider} />
          </Box>
          <Box className={classes.outercont}>
            <Typography className={classes.heading}> cyman island </Typography>
            <Box className={classes.forLinks}>
              <Link to="/">
                <ul>
                  <li>Home</li>
                </ul>
              </Link>
              <Link to="/">
                <ul>
                  <li>All documents</li>
                </ul>
              </Link>
              <Link to="/">
                <ul>
                  <li>legal guides</li>
                </ul>
              </Link>
              <Link to="/">
                <ul>
                  <li>account</li>
                </ul>
              </Link>
              <Link to="/">
                <ul>
                  <li>FAQ</li>
                </ul>
              </Link>
              <Link to="/">
                <ul>
                  <li>contact</li>
                </ul>
              </Link>
              <Link to="/">
                <ul>
                  <li>term and conditions</li>
                </ul>
              </Link>
            </Box>
            <Divider className={classes.divider} />
          </Box>
          <Box className={classes.outercont}>
            <Typography className={classes.heading}> canada </Typography>
            <Box className={classes.forLinks}>
              <Link to="/">
                <ul>
                  <li>Home</li>
                </ul>
              </Link>
              <Link to="/">
                <ul>
                  <li>All documents</li>
                </ul>
              </Link>
              <Link to="/">
                <ul>
                  <li>legal guides</li>
                </ul>
              </Link>
              <Link to="/">
                <ul>
                  <li>account</li>
                </ul>
              </Link>
              <Link to="/">
                <ul>
                  <li>FAQ</li>
                </ul>
              </Link>
              <Link to="/">
                <ul>
                  <li>contact</li>
                </ul>
              </Link>
              <Link to="/">
                <ul>
                  <li>term and conditions</li>
                </ul>
              </Link>
            </Box>
            <Divider className={classes.divider} />
          </Box>
          <Box className={classes.outercont}>
            <Typography className={classes.heading}>united kingdom</Typography>
            <Box className={classes.forLinks}>
              <Link to="/">
                <ul>
                  <li>Home</li>
                </ul>
              </Link>
              <Link to="/">
                <ul>
                  <li>All documents</li>
                </ul>
              </Link>
              <Link to="/">
                <ul>
                  <li>legal guides</li>
                </ul>
              </Link>
              <Link to="/">
                <ul>
                  <li>account</li>
                </ul>
              </Link>
              <Link to="/">
                <ul>
                  <li>FAQ</li>
                </ul>
              </Link>
              <Link to="/">
                <ul>
                  <li>contact</li>
                </ul>
              </Link>
              <Link to="/">
                <ul>
                  <li>term and conditions</li>
                </ul>
              </Link>
            </Box>
            <Divider className={classes.divider} />
          </Box>
          <Box className={classes.outercont}>
            <Typography className={classes.heading}> panama </Typography>
            <Box className={classes.forLinks}>
              <Link to="/">
                <ul>
                  <li>Home</li>
                </ul>
              </Link>
              <Link to="/">
                <ul>
                  <li>All documents</li>
                </ul>
              </Link>
              <Link to="/">
                <ul>
                  <li>legal guides</li>
                </ul>
              </Link>
              <Link to="/">
                <ul>
                  <li>account</li>
                </ul>
              </Link>
              <Link to="/">
                <ul>
                  <li>FAQ</li>
                </ul>
              </Link>
              <Link to="/">
                <ul>
                  <li>contact</li>
                </ul>
              </Link>
              <Link to="/">
                <ul>
                  <li>term and conditions</li>
                </ul>
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SiteMap;
