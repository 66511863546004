import { Box, Button, Toolbar, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import SearchBar from "../SearchBar/SearchBar";
import useStyles from "./Style";

const Data = [
  {
    Content: "Sale and purchase Agreement",
  },
  {
    Content: "Partnership Deed",
  },
  {
    Content: "Employee HandBook",
  },
  {
    Content: "Shareholder Agreement",
  },
  {
    Content: "Sale and purchase Agreement",
  },
  {
    Content: "Sale and purchase Agreement",
  },
  {
    Content: "Sale and purchase Agreement",
  },
];
const FinancalDocument = () => {
  const classes = useStyles();
  return (
    <div className={classes.LegalDocContainer}>
      <div className={classes.header}>
        <Typography variant="h4">
          <b>FINANCIAL DOCUMENTS</b>
        </Typography>
      </div>

      <Box className={classes.search}>
        <SearchBar searchLil searchDoc />
        <Toolbar />
        <Typography align="center">Nothing to show!</Typography>
      </Box>
    </div>
  );
};
export default FinancalDocument;
