import React from "react";
import logo from "../../assest/Logo.png";
import { Box, Typography } from "@material-ui/core";
import { Link, NavLink } from "react-router-dom";
import Button from "../buttons";
import HamBurger from "../HamBurger";
import { makeStyles } from "@material-ui/core";
import MarginWrapper from "../marginwrapper";
import { useDispatch, useSelector } from "react-redux";
import UserLogin from "../userLogin";
import {
  toogleLoginModal,
  toogleSigninModal,
} from "../../Features/signIn/signIn.slicer";

const Header = () => {
  const classes = useStyles();
  const { loginLoadingSucess, login } = useSelector(
    (state) => state.LoginSlicer
  );

  const token = localStorage.getItem("legalJWTtoken");
  const dispatch = useDispatch();

  const handleOpenLoginModal = () => {
    dispatch(toogleLoginModal(true));
  };
  const handleOpenSigupModal = () => {
    dispatch(toogleSigninModal(true));
  };
  return (
    <Box className={classes.headContainer}>
      <MarginWrapper>
        <Box className={classes.headerconatiner}>
          <NavLink to="/">
            <img src={logo} alt="" className={classes.logo} />
          </NavLink>
          <Box
            className={classes.HamburgerClosed}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <NavLink
              to="/home"
              className={({ isActive }) =>
                isActive ? classes.active : classes.notactive
              }
            >
              <Typography variant="h6" className={classes.typo} color="inherit">
                <b> Home</b>
              </Typography>
            </NavLink>
            <NavLink
              to="/documents"
              activeClassName="active"
              className={({ isActive }) =>
                isActive ? classes.active : classes.notactive
              }
            >
              <Typography className={classes.typo} variant="h6" color="inherit">
                <b> Documents</b>
              </Typography>
            </NavLink>
            <NavLink
              to="/FAQs"
              className={({ isActive }) =>
                isActive ? classes.active : classes.notactive
              }
            >
              <Typography className={classes.typo} variant="h6" color="inherit">
                <b> Faq</b>
              </Typography>
            </NavLink>
            <NavLink
              to={`/myaccount/${login?.userInfo?.id}`}
              className={({ isActive }) =>
                isActive ? classes.active : classes.notactive
              }
            >
              <Typography className={classes.typo} variant="h6" color="inherit">
                <b> My Account</b>
              </Typography>
            </NavLink>
          </Box>
          {loginLoadingSucess && token ? (
            <div style={{ display: "flex" }}>
              <UserLogin />
              <HamBurger />
            </div>
          ) : (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              className={classes.authnavabar}
            >
              <Box mr={3} onClick={handleOpenLoginModal}>
                <Typography className={classes.logInButton} variant="body2">
                  LOG IN
                </Typography>
              </Box>

              <Box onClick={handleOpenSigupModal}>
                <Button
                  style={{ fontWeight: "bold" }}
                  variant="outlinedSecondary"
                >
                  CREATE ACCOUNT
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </MarginWrapper>
    </Box>
  );
};

export default Header;

const useStyles = makeStyles((theme) => ({
  HamburgerClosed: {
    display: "flex",
    listStyle: "none",
    gap: "22px",
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: "bold",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  active: {
    color: "#005084",
    position: "relative",
    "&::after": {
      position: "absolute",
      content: "''",
      width: 21,
      height: 3,
      backgroundColor: "#005084",
      left: "50%",
      transform: "translateX(-50%)",
    },
  },
  notactive: {
    color: "#3E3E3E",
  },
  headerconatiner: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headContainer: {
    zIndex: "10000000",
    position: "relative",
    marginTop: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: " #C9EBFF",
    boxShadow: "0px 1px 17px rgba(0, 0, 0, 0.2)",
    [theme.breakpoints.down("md")]: {
      justifyContent: "space-between",
    },
  },
  authnavabar: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  typo: {
    color: theme.palette.text.primary,
    textTransform: "uppercase",
    paddingBottom: 7,

    "&:hover": {
      color: theme.palette.text.hoverColor,
    },
  },
  logInButton: { color: theme.palette.text.primary, fontWeight: "bold" },
  logo: {
    width: 118,
    height: 50,
  },
}));
