
import {  TextField, withStyles } from "@material-ui/core";

export default withStyles((theme) => ({
root:{
  width: "100%",
  border: "1px solid #DEDEDE",
    borderRadius: "10px",
    background: theme.palette.background.inputBg,
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #DEDEDE",
       }
},



 }))(TextField);
