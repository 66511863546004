import { Grid } from "@material-ui/core";
import React from "react";
import SignedDoc from "../../components/signdocument";
import SignTexts from "../../components/signTexts";
import SignDesigns from "../../components/signturedesigns";
import RippleBckground from "../../components/ripplwbackground";
import { Link } from "react-router-dom";

const Myacounts = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <SignedDoc />
      </Grid>
      <Grid item xs={12}>
        <SignDesigns />
      </Grid>
      <Grid item xs={12}>
        <SignTexts />
      </Grid>
    </Grid>
  );
};

export default Myacounts;
