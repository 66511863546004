import { makeStyles } from "@material-ui/core";
import bgImage from "../../assest/background.jpg";
import greybackground from "../../assest/images/greybackground.png";
import backgroundtext from "../../assest/images/Sampledoc.png";
import howitwork from "../../assest/images/howitworks.png";
import whole from "../../assest/bgHeavy.png";
export default makeStyles((theme) => ({
  mg: {
    paddingTop: "110px",

    [theme.breakpoints.down("md")]: {
      paddingTop: "20px",
    },
  },
  axe: {
    width: "100%",
    height: "2200px",
    opacity: "0.4",
  },
  posokay: {
    position: "absolute",
    top: "650px",
    width: "100%",
  },
  // bgWholeImage: {
  //   backgroundSize: "cover",
  //   zIndex: 9999,
  //   position: "relative",
  //   top: 1,
  //   backgroundRepeat: "no-repeat",
  //   backgroundImage: `url(${whole})`,
  //   width: "100%",
  // },
  inputed: {
    width: "40%",
    height: "47px",
    paddingLeft: "20px",
    border: "1px solid white ! important",
    opacity: "10px",
    boxShadow: "2px 4px 8px #c4c4c4",
    outline: "none",
    "&::placeholder": {
      color: "#c4c4c4",
      fontSize: "16px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid red",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  btns: {
    width: "20%",
    fontSize: "18px",
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
  },
  textones: {
    fontSize: "2.5rem",
    fontWeight: "700",
    [theme.breakpoints.down("md")]: {
      textAlign: "left",
    },
  },
  textfour: {
    fontSize: "22px",
    [theme.breakpoints.down("md")]: {
      textAlign: "left",
    },
  },
  textoness: {
    fontSize: "2.5rem",
    fontWeight: "700",
    position: "relative",
    top: "-10px",
    [theme.breakpoints.down("md")]: {
      textAlign: "left",
    },
  },
  layout: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    color: theme.palette.text.primary,
    backgroundImage: `url(${bgImage})`,
    padding: "100px 0px",
    position: "relative",
    overflow: "hidden",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      padding: "20px",
      paddingTop: "50px",
    },
  },
  topconatiner: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  textColor: {
    color: theme.palette.text.primary,
    textAlign: "center",
    marginTop: "40px",
  },
  helpBox: { backgroundImage: `url(${greybackground})`, padding: "5% 0px" },
  image: {
    maxWidth: 378,
    maxHeight: 320,
    [theme.breakpoints.down("sm")]: {
      maxWidth: 310,
      maxHeight: 290,
      textAlign: "center",
    },
  },
  imageAndSearch: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
      flexDirection: "column-reverse",
    },
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
      justifyContent: "center",
    },
  },
  backgroundtext: {
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${backgroundtext})`,
    backgroundPosition: "center center",
    height: "40px",
  },

  HowItWorkImage: {
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${howitwork})`,
    backgroundPosition: "center center",
    position: "relative",
    height: "40px",
  },
  poss: {
    position: "absolute",
    left: "41%",
    top: "15%",
    [theme.breakpoints.down("xs")]: {
      left: "25%",
    },
  },
  dividerPrimary: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
  },
  divider: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      margin: "20px 0px",
    },
  },
  SampleDocCenter: {
    display: "flex",
    justifyContent: "space-between",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    flexWrap: "wrap",

    width: "100%",
    paddingBottom: "20px",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr",

      gap: "30px",
      paddingBottom: "20px",
    },

    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  headingColor: {
    color: theme.palette.icon.main,
  },
}));
