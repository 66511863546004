import React from "react";
import { Box, Typography } from "@material-ui/core";
import RippleBckground from "../ripplwbackground";
import Button from "../../components/buttons";
import PdfIcon from "../../assest/images/Pdficon.png";
import { makeStyles } from "@material-ui/styles";
import { withStyles } from "@material-ui/styles";
import Gavel from "../../assest/images/6.png";
import Lock from "../../assest/images/5.png";
import Dotlines from "../../assest/images/4.png";
import Backgroud from "../../assest/images/background2.jpg";
import { Link } from "react-router-dom";
import MarginWrapper from "../marginwrapper";
const Paragraph = withStyles((theme) => ({
  root: {
    color: "#3E3E3E",
    fontWeight: 800,
  },
}))(Typography);

const SignedDoc = () => {
  const classes = useStyles();
  return (
    <Box pt={10} pb={8} className={classes.mainContainer}>
      <RippleBckground />
      <MarginWrapper>
        <Box className={classes.inner}>
          <Box className={classes.container}>
            <Paragraph className={classes.heading}>Sign A Document</Paragraph>
            <Paragraph className={classes.pdfSigned}>
              A simpe way to get your PDFs Signed
            </Paragraph>
            <Box pt={5} className={classes.typoContainer}>
              <Box className={classes.typoBox}>
                <img src={Gavel} alt="" />
                <Typography className={classes.typographies}>
                  Signature valid in court
                </Typography>
              </Box>
              <Box className={classes.typoBox}>
                <img src={Lock} alt="" />
                <Typography className={classes.typographies}>
                  Signature and timestamp are encrypted by certificate
                </Typography>
              </Box>
              <Box className={classes.typoBox}>
                <img src={Dotlines} alt="" />
                <Typography className={classes.typographies}>
                  Electronic signature is compliant with the <br /> E-SIGN Act
                  and the Uniform Electronic <br /> Transactions Act (UETA)
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box>
            <Box className={classes.uploadContainer}>
              <img className={classes.pdfIcon} src={PdfIcon} alt="" />
              <Paragraph>
                <label htmlFor="upload-photo">
                  <input
                    style={{ display: "none" }}
                    id="upload-photo"
                    name="UPLOAD"
                    type="file"
                  />
                  <Link to="/SignADocument">
                    <Button
                      className={classes.btn}
                      size="small"
                      component="span"
                      aria-label="add"
                      variant="outlinedmain"
                    >
                      Select a PDF file
                    </Button>
                  </Link>
                </label>
              </Paragraph>
              <Paragraph variant="h6" className={classes.dragAndDrop}>
                OR DRAG AND DROP IT HERE
              </Paragraph>
            </Box>
            <Box mt={2} display="flex" justifyContent="center">
              <Link to="/myaccount" className={classes.links}>
                Cancel and return to your account
              </Link>
            </Box>
          </Box>
        </Box>
      </MarginWrapper>
    </Box>
  );
};

export default SignedDoc;

const useStyles = makeStyles((theme) => ({
  inner: {
    gap: 58,
    width: "100%",
    display: "flex",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexWrap: "wrap",
    },
  },
  mainContainer: {
    backgroundImage: `url(${Backgroud})`,
    backgroundRepeat: " no-repeat",
    backgroundSize: "100%",
    position: "relative",
    overflow: "hidden",
  },
  uploadContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #C4C4C4",
    padding: "40px 120px",
    backgroundColor: theme.palette.text.secondary,
    gap: 15,
    flexWrap: "wrap",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: "20px 70px",
    },
  },
  typoBox: {
    display: "flex",
    gap: 20,
    padding: 10,
    alignItems: "center",
  },
  btn: {
    width: 200,
    height: "47px",
    fontSize: 20,
    fontWeight: 700,
    borderRadius: "none",
  },
  dragAndDrop: {
    fontWeight: 700,
    color: "#575757",
  },
  pdfIcon: {
    marginRight: "27px",
  },
  typographies: {
    color: "#3E3E3E",

    fontSize: 18,
    padding: 5,
    lineHeight: "25px",
  },
  typoContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
  },
  heading: {
    fontSize: "36px",
    fontWeight: "bold",
    lineHeight: "49px",
  },
  pdfSigned: {
    fontSize: "20px",
    fontWeight: "bold",
    lineHeight: "27px",
  },
  links: {
    color: "#3FA7DA",
    fontSize: 18,
    padding: 5,
    lineHeight: "25px",
  },
}));
