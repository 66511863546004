import { Box, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/styles";
import Pen from "../../assest/images/pen.png";
import Human from "../../assest/images/human.png";
import Balance from "../../assest/images/balance.png";
import MarginWrapper from "../marginwrapper";
const SignDesigns = () => {
  const classes = useStyles();
  return (
    <Box className={classes.mainContainer}>
      <MarginWrapper>
        <Box className={classes.flexTemplet}>
          <Box className={classes.typoBox}>
            <img src={Pen} alt="" />
            <Box mt={2} mb={2}>
              <Typography variant="h3">CREATE YOUR SIGNATURE</Typography>
            </Box>
            <Typography variant="h5">
              Easily draw and insert your signature
            </Typography>
          </Box>
          <Box mt={4} className={classes.typoBox}>
            <img src={Human} alt="" />
            <Box mt={2} mb={2}>
              <Typography variant="h3">
                ADD SIGNERS <div style={{ color: "transparent" }}>Working</div>
              </Typography>
            </Box>
            <Typography variant="h5">
              Insert places for other people to sign. We will gather all the
              signatures for you.
            </Typography>
          </Box>
          <Box className={classes.typoBox}>
            <img src={Balance} alt="" />
            <Box mt={2} mb={2}>
              <Typography variant="h3">LEGAL SIGNATURE</Typography>
            </Box>
            <Typography variant="h5">
              Get a document that is signed by all parties and admissible in
              court.
            </Typography>
          </Box>
        </Box>
      </MarginWrapper>
    </Box>
  );
};

export default SignDesigns;
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: "#EAF7FF",
    width: "100%",
  },
  flexTemplet: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: "307px",
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
      gridGap: "30px",
      justifyContent: "center",
      padding: "50px 0px",
    },
  },
  typoBox: {
    width: "200px",
    color: "#3E3E3E",
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
  },
}));
