export const termsData = [
  {
    heading: "WHAT IS AN ELECTRONIC SIGNATURE?",
    paragraph: [
      {
        para: "Under the <b> Electronic Signature in Global and National Commerce Act (the E-SIGN Act)</b>, as well as the Uniform Electronic Transactions Act (UETA), an electronic signature is created when someone intends to sign a document and electronically attaches some type of symbol, like their name or initials, to that document. Under both the E-SIGN Act and UETA, electronic signatures are valid and given legal effect.",
      },
      {
        para: "However, some electronic signatures are easy to change - for example, writing your name at the end of a Word document or pasting a drawing of your signature on a PDF are both subject to being manipulated. These types of signatures, though they are still considered electronic signatures, would be easily contested in court. That's why secure electronic signature providers like Wonder.Legal use specific technical protocols to encrypt signatures in a way that allows for strong authentication - making it difficult or impossible to refute these signatures in a court of law.",
      },
      {
        para: "At Wonder.Legal, we create a unique encrypted digital signature using standard, well-established protocols. The encryption certifications used by Wonder.Legal make our electronic signatures binding and verifiable. Importantly, this means that the document can’t be changed after it is signed - giving you the security of knowing that your electronically signed document is secure.",
      },
    ],
    list: [],
  },

  {
    heading: "WHERE IS THE SIGNATURE?",
    paragraph: [{ para: "The signature is found in two places" }],
    list: [
      {
        li: "The simple signature is the image of the signatures that the signers drew themselves. You can see the signatures in the document at the positions you have chosen. ",
      },
      {
        li: "The advanced signature is an encrypted digital signature that we place directly in the PDF. That is the signature that is legally binding.     You can view it with many PDF readers, particularly Adobe Reader.",
      },
    ],
  },
  {
    heading: "How can I see the advanced signature with Adobe Reader?",
    paragraph: [
      {
        para: "An advanced signature can be viewed with many PDF readers. But be aware that it is not visible if you open the PDF in your browser.",
      },
    ],
    list: [
      {
        li: "If you haven't done so yet, download Adobe Reader ",
      },
      {
        li: "Open your signed PDF with Adobe Reader ",
      },
      {
        li: "You will automatically see the 'signatures' button appear in the top right",
      },
      {
        li: "Click on this button and you will see a panel on the left containing all the information about the encrypted signature",
      },
    ],
  },

  {
    heading: "Do I need legal and financial once the document is signed?",
    paragraph: [
      {
        para: "No. Once the document has been signed, all the parties receive a copy by email. The signature is integrated into each copy. You no longer need us. There is no need for a subscription to save or certify your signatures.",
      },
    ],
    list: [],
  },
  {
    heading: "How do the other signers sign?",
    paragraph: [
      {
        para: "Each signer receives an email inviting them to sign the document at the places you have selected for them. The other signers can simply sign or refuse to sign. They cannot add any text or dates to the document or even change the placement of their signatures.",
      },
    ],
    list: [],
  },
  {
    heading:
      "What happens if a signer refuses to sign or if I cancel the signing process?",
    paragraph: [
      {
        para: "If one of the signers refuses to sign, the signing process stops. You can also cancel the signing process from your account. In both cases, the document becomes unavailable and all the signers are notified.",
      },
    ],
    list: [],
  },
  {
    heading: "How can I keep track of the signing process?",
    paragraph: [
      {
        para: "You will receive an email as soon as a signer signs the document or refuses to sign. You can also follow the complete history of the signing process from <Link to='/myaccount'>your account</Link>.",
      },
    ],
    list: [],
  },
];
