const white = "#FFFFFF";
const black = "#e8ebe5";
const palette = {
  black,
  white,
  primary: {
    contrastText: black,
    // darkblue
    main: "#03228f",
  },
  secondary: {
    contrastText: white,
    // white
    main: "#ffff",
  },
  main: {
    // lightblue
    primary: "#3FA7DA",
  },
  text: {
    // off black
    primary: "#3E3E3E",
    // white
    secondary: "#ffff",
    // white-grey
    placeholder: "#C4C4C4",
    main: " #626262",
    hoverColor: "#005084",
  },
  background: {
    // lightgrey
    primary: "#C4C4C4",
    // blue
    secondary: "#5CB4E4",
    // lightblue
    main: "#C9EBFF",
    //lightbluewithsomeopacityeffect
    bluebackground: "#EBF6FF",
    inputBg: "#FAFAFA",
    banner: "#E5E5E5",
    alpha: "#EAF7FF",
    beta: "#FAFAFA",
    btnbackground: "#E33131",
  },
  icon: {
    // orange
    yellow: "yellow",
    main: "#EB9A28",
    secondary: "#626262",
    alpha: "#4A9F42",
  },
};

export default palette;
