import Buttons from "../buttons";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Typography, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { toogleLoginModal } from "../../Features/signIn/signIn.slicer";
import Loading from "../loading";
import { load } from "cheerio";

const DocumentViewer = ({ html, data, handleSubmit, title }) => {
  const [inputValues, setInputValues] = useState(
    data === "undefined" ? data : JSON.parse(data)
  );
  const navigate = useNavigate();
  const { id } = useParams();
  const classes = useStyles();
  const userId = useSelector((state) => state.LoginSlicer.login?.userInfo?.id);
  const { loginLoadingSucess } = useSelector((state) => state.LoginSlicer);
  const { isDocumentSaveLoading } = useSelector(
    (state) => state.getSingleDocById
  );
  const dispatch = useDispatch();

  const $ = load(html, {}, false);

  const handlePrintClick = () => {
    navigate("/ordersecure");
  };

  const handleClick = (e) => {
    if (loginLoadingSucess) {
      let values = {};
      $("input").each((index) => {
        const value = document.querySelector(`.input-${index}`).value;
        setInputValues((prev) => ({
          ...prev,
          [`input-${index}`]: value,
        }));
        values = { ...values, [`input-${index}`]: value };
      });

      handleSubmit({ docId: id, userId: userId, data: JSON.stringify(values) });
    } else {
      dispatch(toogleLoginModal(true));
    }
  };

  if (inputValues !== "undefined") {
    $("input").each((index, element) => {
      $(element).addClass(`input-${index}`).val(inputValues[`input-${index}`]);
    });
  } else {
    $("input").each((index, element) => {
      $(element).addClass(`input-${index}`);
    });
  }

  useEffect(() => {
    const inputEvent = new InputEvent("input", {
      bubbles: true,
      cancelable: true,
    });
    $("input").each((index) => {
      const inputField = document.querySelector(`.input-${index}`);
      inputField && inputField.dispatchEvent(inputEvent);
    });
  }, [$]);

  return (
    <div className={classes.root}>
      <div className={classes.titleWrapper}>
        <Typography variant="h3" className={classes.title} align="center">
          {title}
        </Typography>
      </div>
      <div>{$ && <InnerHTML html={$.html()} />}</div>{" "}
      <div>
        <div className={classes.buttonWrapper}>
          <Buttons onClick={handleClick}>Save</Buttons>
          <Buttons onClick={handlePrintClick}>Print</Buttons>
        </div>
      </div>
      {isDocumentSaveLoading && <Loading />}
    </div>
  );
};

export default DocumentViewer;

function InnerHTML(props) {
  const { html } = props;
  const divRef = useRef(null);
  const isAppended = useRef(false);

  useEffect(() => {
    if (html) {
      const parsedHTML = document.createRange().createContextualFragment(html);
      if (!isAppended.current) {
        divRef.current.appendChild(parsedHTML);
        isAppended.current = true;
      }
    }
  }, [html]);

  return <div ref={divRef} />;
}

const useStyles = makeStyles((theme) => ({
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
    gap: 10,
    paddingBottom: 40,
  },
  root: {
    position: "relative",
    "& .p-5": {
      padding: "0 !important",
    },
  },
  title: {
    color: "#2ca0a7",
  },
  titleWrapper: {
    padding: "30px 0",
  },
}));
