import { Skeleton } from "@material-ui/lab";

const DocumentSkeleton = () => {
  return (
    <div style={{ padding: "50px 0" }}>
      <div
        style={{
          padding: 10,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",

          justifyContent: "center",
        }}
      >
        <Skeleton variant="text" width="500px" />
        <Skeleton variant="text" width="300px" />
      </div>
      <div
        style={{
          padding: 10,
          display: "flex",
          alignItems: "flex-start",
          gap: 10,
          justifyContent: "center",
        }}
      >
        <Skeleton variant="rect" width="700px" />
      </div>
      <div
        style={{
          padding: 10,
          display: "flex",
          alignItems: "flex-start",
          gap: 10,
          justifyContent: "center",
        }}
      >
        <Skeleton variant="rect" height="500px" width="500px" />
        <Skeleton variant="rect" height="500px" width="200px" />
      </div>
    </div>
  );
};

export default DocumentSkeleton;
