import { Box, Divider, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core";
import { termsData } from "./constant";
import MarginWrapper from "../marginwrapper";
const SignTexts = () => {
  const classes = useStyles();
  return (
    <div className={classes.bgWhite}>
      <MarginWrapper>
        <Box className={classes.mainContainer}>
          {termsData.map(({ heading, paragraph, list = [] }) => (
            <Box>
              <Typography gutterBottom className={classes.heading}>
                {heading}
              </Typography>
              <div>
                {paragraph &&
                  paragraph.map(({ para }) => (
                    <Typography
                      variant="h5"
                      dangerouslySetInnerHTML={{ __html: para }}
                    />
                  ))}
              </div>
              <ol>
                {list &&
                  list.map(({ li }) => (
                    <Typography variant="h5">
                      <li className={classes.list}>{li}</li>
                    </Typography>
                  ))}
              </ol>
              <Divider className={classes.divider} />
            </Box>
          ))}
        </Box>
      </MarginWrapper>
    </div>
  );
};

export default SignTexts;
const useStyles = makeStyles((theme) => ({
  bgWhite: {
    paddingBottom: "12%",
    backgroundColor: "#FAFAFA",
  },
  mainContainer: {
    padding: "30px",
    position: "relative",
    top: "50px",
    color: "#3E3E3E",
    background: "white",
    [theme.breakpoints.down("md")]: {
      padding: "70px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "40px",
    },
  },
  list: {
    marginLeft: "50px",
  },

  heading: {
    textTransform: "uppercase",
    paddingTop: 20,
    fontSize: "20px",
    fontWeight: "bold",
  },
  divider: {
    marginTop: 25,
  },
}));
