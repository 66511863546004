import React, { useEffect, useState } from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import InputField from "../inputfeild";
import SignatureCanvas from "react-signature-canvas";
import Button from "../buttons";
import useWidthHeight from "../../hooks/useWidthHeight";

export default function TransitionsModal(props) {
  const classes = useStyles();

  const handleClose = () => {
    props.setOpen(false);
  };
  const { width } = useWidthHeight();
  const [canvasSize, setCanvasSize] = useState({
    width: 500,
    height: 190,
  });

  useEffect(() => {
    if (width < 1300) {
      setCanvasSize({ width: 500, height: 190 });
    }
    if (width < 600) {
      setCanvasSize({ width: 440, height: 140 });
    }
    if (width < 450) {
      setCanvasSize({ width: 310, height: 120 });
    }
  }, [width]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <div className={classes.paper}>
            <Box className={classes.inputField} pt={2} pl={2} pr={2}>
              <Typography className={classes.labelText} variant="h6">
                Enter your full name:
              </Typography>

              <InputField variant="outlined" className={classes.inputfeild} />
            </Box>
            <Box className={classes.inputField} pt={3} pl={2} pr={2}>
              <Typography className={classes.labelText} variant="h6">
                Your email address:
              </Typography>

              <InputField variant="outlined" className={classes.inputfeild} />
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              pt={3}
              pl={2}
              pr={2}
            >
              <Typography className={classes.labelText} variant="h6">
                Draw your signature here
              </Typography>

              <button className={classes.clearButton}>clear</button>
            </Box>
            <Box display="flex" justifyContent="center">
              <div className={classes.signatureCanvas}>
                <SignatureCanvas
                  canvasProps={{
                    width: canvasSize.width,
                    height: canvasSize.height,
                  }}
                />
              </div>
            </Box>

            <Box mt={3} display="flex" justifyContent="center">
              <Button variant="outlined">
                <Typography variant="h6" className={classes.modalButton}>
                  cancel
                </Typography>
              </Button>
              <Box ml={1}>
                <Link to="/SignatureAdd">
                  <Button variant="outlinedmain">
                    <Typography variant="h6" className={classes.modalButton}>
                      save and modify the signature
                    </Typography>
                  </Button>
                </Link>
              </Box>
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 4,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: 600,
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      width: "400px",
    },
  },
  labelText: {
    textTransform: "capitalize",
    fontWeight: "bold",
    color: theme.palette.text.primary,
  },
  inputField: {
    width: "75%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  clearButton: {
    border: "none",
    backgroundColor: "transparent",

    cursor: "pointer",
  },
  signatureCanvas: {
    maxWidth: 500,
    maxWheight: 190,
    border: `1px solid ${theme.palette.background.primary}`,
    [theme.breakpoints.down("xs")]: {
      width: "310px",
    },
  },
  modalButton: { fontWeight: "bold" },
  inputfeild: {
    border: "none",
    "& .MuiOutlinedInput-input": {
      padding: 7,
    },
  },
}));
