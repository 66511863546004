import { alpha, Button, withStyles } from "@material-ui/core";

/**
 * @dev custom button designed upon Material UI's Button component. It accepts all props the MuI button accepts. moreover, the following props have been overridden. the default styles is contained primary
 * @props variant can be either contained or outlined
 * @props color can be primary or secondary
 */
export default withStyles((theme) => ({
  root: {
    // boxShadow: "0 0 10px -5px",
    borderWidth: 1.8,
    fontSize: 12,
    fontWeight: 500,
    color: "white",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.dark, 0.9),
    },
  },
  // greyWhite Button
  outlined: {
    background: theme.palette.background.inputBg,
    border: "1px solid #C4C4C4",
    color: theme.palette.text.main,
    borderRadius: "5px",
    "&:hover": {
      color: theme.palette.secondary.main,
      backgroundColor: alpha(theme.palette.icon.secondary, 0.9),
    },
  },
  // Crystal White Button
  containedSecondary: {
    color: "var(--black)",
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.8),
      boxShadow: "7px 6px 17px rgba(0, 0, 0, 0.15)",
    },
  },
  // Dark Grey Button
  outlinedPrimary: {
    background: "#E6E6E6",
    borderRadius: "5px",
    border: "1px solid #C4C4C4",
    color: theme.palette.text.primary,
    "&:hover": {
      border: "1px solid #C4C4C4",
      backgroundColor: alpha(theme.palette.secondary.main, 0.8),
    },
  },
  // Grey Dark
  outlinedSecondary: {
    background: "#575757",
    borderRadius: "5px",
    border: "1px solid #575757",
    "&:hover": {
      color: "black",
      backgroundColor: alpha(theme.palette.secondary.main, 0.8),
    },
  },
  // Orange color hover color grey
  outlinedmain: {
    color: theme.palette.text.secondary,
    borderRadius: 5,
    backgroundColor: alpha(theme.palette.icon.main, 0.9),
    "&:hover": {
      background: theme.palette.icon.secondary,
    },
  },
  // secure button in payment
  ButtonSecure: {
    color: theme.palette.icon.secondary,
    borderRadius: 5,
    backgroundColor: alpha(theme.palette.icon.main, 0.9),
    "&:hover": {
      background: theme.palette.text.secondary,
    },
  },
  outlinedmainnoborder: {
    borderRadius: "0px",
    boxShadow: "2px 4px 8px #c4c4c4",
    color: theme.palette.text.secondary,
    backgroundColor: alpha(theme.palette.icon.main, 0.9),
    "&:hover": {
      background: theme.palette.icon.secondary,
    },
  },
  outlinedButton: {
    background: theme.palette.background.primary,
    borderRadius: "5px",
    fontSize: 18,
    color: theme.palette.text.secondary,
    "&:hover": {
      border: "1px solid #C4C4C4",
      color: theme.palette.text.primary,
      backgroundColor: alpha(theme.palette.secondary.main, 0.8),
    },
  },
}))(Button);
