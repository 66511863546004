import { createAsyncThunk } from "@reduxjs/toolkit";
import { getDocumentsByCategoryApi } from "./api";

export const getDocumentByCategory = createAsyncThunk(
  "document/getDocumentByCategory",
  async () => {
    try {
      const res = await getDocumentsByCategoryApi();
      return res.data;
    } catch (error) {
      throw error;
    }
  }
);
