import React, { Suspense, lazy } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import UnAuthNavbar from "./components/navbars/UnAuth";
import UserLayout from "./layouts/User";

import SignatureScroll from "./components/signADocumentScrollform";
import Home from "./pages/home";
import ShowSignatureForm from "./components/showSignatureForm";
import SignatureForm from "./components/signatureForm";
import ModifySigns from "./components/modifySigns";
import Signaturecanvas from "./pages/signADocument";
import MyAcounts from "./pages/myacount";
import DeleteAccount from "./pages/deleteYourAccount";
import ConfirmDelete from "./pages/confirmDeletion";
import SiteMap from "./pages/siteMap";
import PrivateRoute from "./components/PrivateRoute";
import DocumentRoute from "./pages/testRoute";
import { CircularProgress } from "@material-ui/core";
import UniversalDialogs from "./UniversalDialogs";
import StripePayment from "./pages/stripe";
import EditDocument from "./pages/editDocument";
import { useSelector } from "react-redux";

// lazy load components upon user interaction
const EnterPassword = lazy(() => import("./pages/auth/enterpasswordform"));
const PasswordReset = lazy(() => import("./pages/auth/passwordreset"));

const ComingSoon = lazy(() => import("./pages/comingSoon"));

const Mainpage = lazy(() => import("./pages/documnets/mainpage"));
const BoilerPage = lazy(() => import("./pages/boilerpage"));
const FormFillTemplate = lazy(() => import("./pages/formFillTemplate"));
const Dashboard = lazy(() => import("./pages/myaccountDashboard"));
const DocumentReadyToSign = lazy(() => import("./pages/documentReadyTOSign"));
const Legalguidance = lazy(() => import("./pages/legalguidance"));
const PurchasebyCreditDebit = lazy(() =>
  import("./pages/creditAndDebitScured")
);
const FAQs = lazy(() => import("./pages/FAQ"));
const TermsAndCondition = lazy(() => import("./pages/TermsAndCondition"));
const ViewAll = lazy(() => import("./pages/viewAll"));
const ContactUs = lazy(() => import("./pages/contactUs/index"));
const LegalGuidlineDetail = lazy(() =>
  import("./pages/TnCguidlineDetails/index")
);
const RootRouter = () => {
  return (
    <Suspense
      fallback={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      }
    >
      <UniversalDialogs />
      <Routes>
        {["/"].map((path, i) => (
          <Route key={i} path={path} element={<BoilerPage />} />
        ))}
        <Route
          path="/home"
          exact
          element={
            <UserLayout Navbar={UnAuthNavbar}>
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            </UserLayout>
          }
        />
        {/* <Route
          path="/home/:id"
          exact
          element={
            <UserLayout Navbar={UnAuthNavbar}>
              <Home />
            </UserLayout>
          }
        /> */}
        <Route
          path="/filloutpage"
          exact
          element={
            <UserLayout Navbar={UnAuthNavbar}>
              <PrivateRoute>
                {" "}
                <FormFillTemplate />{" "}
              </PrivateRoute>
            </UserLayout>
          }
        />

        <Route path="comingSoon" exact element={<ComingSoon />} />

        <Route
          path="/stripe"
          exact
          element={
            <PrivateRoute>
              {" "}
              <StripePayment />
            </PrivateRoute>
          }
        />
        <Route
          path="auth/password-reset"
          element={
            <UserLayout Navbar={UnAuthNavbar}>
              <PasswordReset />
            </UserLayout>
          }
        />
        <Route
          path="auth/reset-password"
          element={
            <UserLayout Navbar={UnAuthNavbar}>
              <PrivateRoute>
                {" "}
                <EnterPassword />
              </PrivateRoute>
            </UserLayout>
          }
        />
        <Route
          path="/documents"
          element={
            <UserLayout Navbar={UnAuthNavbar}>
              {/* <Mainpage /> */}
              <PrivateRoute>
                <ViewAll />
              </PrivateRoute>
            </UserLayout>
          }
        />
        <Route
          path="/document/:id"
          element={
            <UserLayout Navbar={UnAuthNavbar}>
              <PrivateRoute>
                <Mainpage />
              </PrivateRoute>
            </UserLayout>
          }
        />
        {/* Test Route */}

        <Route
          path="/docs/:id"
          element={
            <UserLayout Navbar={UnAuthNavbar}>
              <PrivateRoute>
                <DocumentRoute />
              </PrivateRoute>
            </UserLayout>
          }
        />
        <Route
          path="/editdocument/:id"
          element={
            <PrivateRoute>
              <UserLayout Navbar={UnAuthNavbar}>
                <EditDocument />
              </UserLayout>
            </PrivateRoute>
          }
        />
        {/* Test Route End */}
        <Route
          path="/myaccount/:id"
          element={
            <PrivateRoute>
              <UserLayout Navbar={UnAuthNavbar}>
                <Dashboard />
              </UserLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="/deleteAccount"
          element={
            <PrivateRoute>
              <UserLayout Navbar={UnAuthNavbar}>
                <DeleteAccount />
              </UserLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="/confirmdeleteAccount"
          element={
            <PrivateRoute>
              <UserLayout Navbar={UnAuthNavbar}>
                <ConfirmDelete />
              </UserLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="/signdocument"
          element={
            <PrivateRoute>
              <UserLayout Navbar={UnAuthNavbar}>
                <MyAcounts />
              </UserLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="/SignADocument"
          exact
          element={
            <UserLayout Navbar={UnAuthNavbar}>
              <Signaturecanvas>
                <SignatureScroll />
                <SignatureForm />
              </Signaturecanvas>
            </UserLayout>
          }
        />

        <Route
          path="/SignatureAdd"
          exact
          element={
            <UserLayout Navbar={UnAuthNavbar}>
              <Signaturecanvas>
                <SignatureScroll />
                <ShowSignatureForm />
              </Signaturecanvas>
            </UserLayout>
          }
        />

        <Route
          path="/modifysignature"
          exact
          element={
            <UserLayout Navbar={UnAuthNavbar}>
              <Signaturecanvas>
                <SignatureScroll />
                <ModifySigns />
              </Signaturecanvas>
            </UserLayout>
          }
        />
        <Route
          path="/ordersecure"
          element={
            <UserLayout Navbar={UnAuthNavbar}>
              <DocumentReadyToSign />
            </UserLayout>
          }
        />
        <Route
          path="/debitsecure"
          element={
            <UserLayout Navbar={UnAuthNavbar}>
              <PurchasebyCreditDebit />
            </UserLayout>
          }
        />
        <Route
          path="/FAQs"
          element={
            <UserLayout Navbar={UnAuthNavbar}>
              <FAQs />
            </UserLayout>
          }
        />
        <Route
          path="/viewall"
          element={
            <UserLayout Navbar={UnAuthNavbar}>
              <ViewAll />
            </UserLayout>
          }
        />
        <Route
          path="/sitemap"
          element={
            <UserLayout Navbar={UnAuthNavbar}>
              <SiteMap />
            </UserLayout>
          }
        />
        <Route
          path="/Legalguidance"
          element={
            <UserLayout Navbar={UnAuthNavbar}>
              <Legalguidance />
            </UserLayout>
          }
        />

        <Route
          path="/contactus"
          element={
            <UserLayout Navbar={UnAuthNavbar}>
              <ContactUs />
            </UserLayout>
          }
        />
        <Route
          path="/LegalGuidlineDetail"
          element={
            <UserLayout Navbar={UnAuthNavbar}>
              <LegalGuidlineDetail />
            </UserLayout>
          }
        />
        <Route
          exact
          path="/templates"
          element={<Navigate to={"/form.html"} replace />}
        />

        <Route
          path="/TermsAndCondition"
          element={
            <UserLayout Navbar={UnAuthNavbar}>
              <TermsAndCondition />
            </UserLayout>
          }
        />
        {/* <Route path="*" element={<Page404 />} /> */}
      </Routes>
    </Suspense>
  );
};

export default RootRouter;
