import React from "react";
import { Box, Typography } from "@material-ui/core";
import FormFillHead from "../../components/formfillHeadSection";
import useStyles from "./styles";
import MarginWrapper from "../../components/marginwrapper";
import RippleBckground from "../../components/ripplwbackground";
const Signaturecanvas = ({ children }) => {
  const classes = useStyles();

  return (
    <Box className={classes.main}>
      <RippleBckground />
      <FormFillHead>
        <Typography variant="h2">Sign A Document</Typography>
      </FormFillHead>
      <MarginWrapper>
        <Box className={classes.body_grid}>
          {children[0]}
          {children[1]}
        </Box>
      </MarginWrapper>
    </Box>
  );
};

export default Signaturecanvas;
