import { useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { SwipeableDrawer } from "@material-ui/core";
import { List } from "@material-ui/core";
import { ListItem } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { useNavigate } from "react-router-dom";
import Button from "../buttons";
import { makeStyles } from "@material-ui/core";

const HamBurger = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  function handleNav(link) {
    navigate(link);
  }

  return (
    <div className={classes.RealHamburger}>
      <IconButton
        style={{ color: "white" }}
        edge="start"
        fontSize="large"
        aria-lable="open drawer"
        onClick={() => {
          setOpen(true);
        }}
      >
        <MenuIcon className={classes.iconColor} />
      </IconButton>
      <SwipeableDrawer
        anchor="right"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onOpen={() => {}}
      >
        <Box p={2}>
          {open ? (
            <KeyboardArrowRightIcon
              variant="contained"
              color="primary"
              fontSize="large"
              onClick={() => {
                setOpen(false);
              }}
            />
          ) : (
            <KeyboardArrowLeftIcon
              variant="contained"
              color="primary"
              fontSize="large"
            />
          )}
        </Box>
        <List>
          <ListItem>
            {/* Put items Here */}
            <Box className={classes.HeaderLinks}>
              <Box onClick={() => handleNav("/home")}>
                <Typography className={classes.HeaderItems}>HOME</Typography>
              </Box>
              <Box onClick={() => handleNav("/documents")}>
                <Typography className={classes.HeaderItems}>
                  DOCUMENTS
                </Typography>
              </Box>
              <Box>
                <Typography
                  onClick={() => handleNav("/FAQs")}
                  className={classes.HeaderItems}
                >
                  FAQ
                </Typography>
              </Box>
              <Box onClick={() => handleNav("/mintformpage")}>
                <Typography className={classes.HeaderItems}>
                  MY ACCOUNT
                </Typography>
              </Box>
              <Box
                onClick={() => {
                  handleNav("/auth/sign-in");
                }}
              >
                <Typography className={classes.HeaderItems}>LOG IN</Typography>
              </Box>
              <Box onClick={() => handleNav("/auth/sign-up")}>
                <Box>
                  <Button variant="outlinedSecondary">CREATE ACCOUNT</Button>
                </Box>
              </Box>
            </Box>
          </ListItem>
        </List>
        <Divider />
      </SwipeableDrawer>
    </div>
  );
};

export default HamBurger;
const useStyles = makeStyles((theme) => ({
  HeaderItems: {
    cursor: "pointer",
    padding: "10px",
    "&:hover": {
      color: theme.palette.text.hoverColor,
    },
  },
  RealHamburger: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "block",
      paddingLeft: "20px",
    },
  },
  iconColor: {
    color: theme.palette.text.primary,
  },
}));
