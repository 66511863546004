import React from "react";
import {
  List,
  ListSubheader,
  makeStyles,
  Paper,
  Divider,
  Typography,
  ListItem,
} from "@material-ui/core";

import { useSelector } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";

import { useNavigate } from "react-router-dom";
import DescriptionIcon from "@material-ui/icons/Description";

const SearchDropDown = ({ close = () => {} }) => {
  const {
    ishomeSearchLoading,
    ishomeSearchLoadingSuccess,

    data,
  } = useSelector((state) => state.searchHome);
  const classes = useStyles();

  const noMatch = data.length === 0;

  if (!ishomeSearchLoading && ishomeSearchLoadingSuccess && noMatch)
    return (
      <Paper className={classes.searchedpaper} elevation={3}>
        <Typography className={classes.nomatchtext}>No Match Found</Typography>
      </Paper>
    );

  return (
    <>
      {ishomeSearchLoading ? (
        <SkeletonLoading />
      ) : (
        !noMatch && (
          <Paper className={classes.searchedpaper} elevation={3}>
            <List className={classes.root} subheader={<li />}>
              <li className={classes.listSection}>
                <StcikyListCompo
                  heading="Documents"
                  data={data}
                  close={close}
                />
              </li>
            </List>
          </Paper>
        )
      )}
    </>
  );
};

export default SearchDropDown;

export const StcikyListCompo = ({
  heading,
  data,

  close,
}) => {
  const classes = useStyles();

  const navigate = useNavigate();

  const handleNavigation = (id) => {
    navigate(`/document/${id}`);
    close();
  };

  return (
    <>
      {Boolean(data.length) && (
        <ul className={classes.ul}>
          <ListSubheader className={classes.listheading}>
            {heading}
            <Divider className={classes.divider} />
          </ListSubheader>

          <div className={classes.serachedrow}>
            {data.map((item, key) => {
              return (
                <ListItem
                  key={key}
                  onClick={() => {
                    handleNavigation(item.id);
                  }}
                >
                  <div className={classes.serchedData}>
                    <DescriptionIcon
                      style={{ padding: "0px", color: "#E29537" }}
                    />
                    <Typography variant="h6" style={{ padding: "0px" }}>
                      {item.title}
                    </Typography>
                  </div>
                </ListItem>
              );
            })}
          </div>
        </ul>
      )}
    </>
  );
};

const SkeletonLoading = () => {
  const classes = useStyles();
  return (
    <Paper className={classes.searchedpaper} elevation={3}>
      {["DOCUMENTS"].map((heading) => (
        <SkeletonLoadingUnit heading={heading} />
      ))}
    </Paper>
  );
};

const SkeletonLoadingUnit = ({ heading }) => {
  const classes = useStyles();
  return (
    <ul style={{ padding: "0px" }}>
      <ListSubheader className={classes.listheading}>{heading}</ListSubheader>

      <Divider variant="middle" />
      <div className={classes.serachedrow}>
        <div className={classes.skelton}>
          <Skeleton width={40} height={40} />

          <Skeleton
            animation="wave"
            width="80%"
            style={{ marginLeft: "10px" }}
          />
        </div>
      </div>
    </ul>
  );
};

const useStyles = makeStyles((theme) => ({
  searchedpaper: {
    position: "absolute",
    maxWidth: "370px",
    minWidth: "370px",
    borderRadius: "10px",
    left: 20,
    top: 50,

    zIndex: 1000,
    backgroundColor: "white",
    [theme.breakpoints.up("xl")]: {
      left: "300px",
      width: "50%",
    },
    padding: "0px 10px",
    "&::-webkit-scrollbar": {
      width: "12px",
    },
    "&::-webkit-scrollbar-track": {
      // "-webkit-box-shadow": "inset 0 0 10px rgba(0,0,0,0.2)",
      margin: "15px 0px",
      backgroundColor: "#e29537",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
    },
  },
  nomatchtext: {
    padding: "30px",
    textAlign: "center",
  },
  root: {
    position: "relative",

    overflow: "auto",
    maxHeight: 230,
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: "12px",
    },
    "&::-webkit-scrollbar-track": {
      // "-webkit-box-shadow": "inset 0 0 10px rgba(0,0,0,0.2)",
      margin: "15px 0px",
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#e29537",
      borderRadius: "10px",
    },
  },
  listSection: {
    backgroundColor: "inherit",
  },

  ul: {
    padding: 0,
  },
  userimage: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
  },
  serachedrow: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
    minWidth: "100%",
    padding: "10px",
    "& li": {
      padding: "10px 0px",
    },
  },
  serchedData: {
    display: "flex",
    alignItems: "center",
    color: "var(--secondary-color)",
    fontWeight: "light",
    width: "100%",
  },
  listheading: {
    height: "max-content",
    color: "black",
    backgroundColor: "white",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  skelton: {
    display: "flex",
    width: "100%",
  },

  divider: {
    backgroundColor: "#e29537",
  },
  media: {
    objectFit: "cover",
    width: "50px",
    height: "40px",
    maxWidth: "100%",
    cursor: "pointer",

    borderRadius: "50%",
  },
}));
