import React, { useMemo, useState } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useDispatch } from "react-redux";
import { postPayment } from "../../Features/payment/payment.actions";

const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
        loader: "always",
      },
    }),
    []
  );

  return options;
};

const SplitForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [error, setError] = useState({});
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
    });
    if (payload.error) setError(payload.error);
    else {
      setError({});
      // dispatch(postPayment({ paymentMethodId: payload.paymentMethod.id }));
      console.log(payload);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {error.message && (
        <Box pb={2}>
          {" "}
          <Alert severity="error">
            <Typography>{error.message}</Typography>
          </Alert>
        </Box>
      )}
      <Box className={classes.label}>
        <Typography variant="h5">Card number</Typography>
        <CardNumberElement options={{ ...options, showIcon: true }} />
      </Box>
      <Box className={classes.label}>
        <Typography variant="h5">Expiration date</Typography>

        <CardExpiryElement options={options} />
      </Box>
      <Box className={classes.label}>
        <Typography variant="h5"> CVC</Typography>

        <CardCvcElement options={options} />
      </Box>
      <button type="submit" disabled={!stripe} className={classes.button}>
        Pay
      </button>
    </form>
  );
};

export default SplitForm;

const useStyles = makeStyles(() => ({
  label: {
    color: "#6b7c93",
    fontWeight: 300,
    "& .StripeElement": {
      display: "block",
      width: "100%",
      margin: "10px 0 20px 0",
      padding: "10px 14px",
      fontSize: "1em",
      boxShadow:
        "rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px",
      border: "0",
      outline: "0",
      borderRadius: "4px",
      background: "white",
      "& input": {
        "&:focus": {
          ".StripeElement--focus": {
            boxShadow:
              "rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px",
            transition: "all 150ms ease",
          },
        },
      },
    },
  },
  button: {
    whiteSpace: "nowrap",
    border: "0",
    outline: "0",
    display: "inline-block",
    height: "40px",
    lineHeight: "40px",
    padding: "0 14px",
    boxShadow:
      "0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)",
    color: "#fff",
    borderRadius: "4px",
    fontSize: "15px",
    fontWeight: "600",
    textTransform: "uppercase",
    letterSpacing: "0.025em",
    backgroundColor: "#6772e5",
    textDecoration: "none",
    WebkitTransition: "all 150ms ease",
    transition: "all 150ms ease",
    marginTop: "10px",
    cursor: "pointer",
  },
}));
