import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Link, useNavigate, useParams } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import Divider from "@material-ui/core/Divider";
import { AllFlags } from "../../Features/allFlag/allflag.action";
import { COUNTRYIMAGE_URL } from "../../HTTP/urls";
import { areEqual } from "../../utils/common";
import { CircularProgress } from "@material-ui/core";

const Flags = ({ height, focus = false }) => {
  const dispatch = useDispatch();
  let { id } = useParams();
  useEffect(() => {
    dispatch(AllFlags());
  }, [dispatch]);

  const { countriesFlagsLoadedSucess, countriesFlags, countriesFlagsLoading } =
    useSelector((state) => state.AllcountriesFlag);

  const classes = useStyles();
  const [icon, setIcon] = useState(true);
  const navigate = useNavigate();

  const CountryBox = withStyles({
    listbox: {
      maxHeight: height,
      "&::-webkit-scrollbar": {
        width: "12px",
      },
      "&::-webkit-scrollbar-track": {
        // "-webkit-box-shadow": "inset 0 0 10px rgba(0,0,0,0.2)",
        margin: "15px 0px",
        backgroundColor: "transparent",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#ccc",
        borderRadius: "10px",
      },
    },

    paper: {
      position: "relative",
      borderRadius: "10px",

      marginTop: 7,
    },
    popper: {
      "&::before": {
        content: "''",
        height: 0,
        width: 0,
        borderWidth: "0px 13px 17px 12px",
        border: " solid black",
        borderColor: "transparent",
        borderBottomColor: "#fff",
        borderStyle: "solid",
        position: "absolute",
        top: -8,
        zIndex: 2075,
        right: 10,
        filter: " drop-shadow(0px -1px 1px #00000033)",
      },
    },
  })(Autocomplete);
  return (
    <>
      <CountryBox
        open={countriesFlagsLoadedSucess}
        loading={countriesFlagsLoading}
        loadingText="loading countries please wait ..."
        id="country-select-demo"
        style={{ width: 300 }}
        options={countriesFlags}
        disablePortal={true}
        classes={{
          option: classes.option_main_div,
        }}
        getOptionLabel={(option) => option.country}
        renderOption={(option) => (
          <div
            style={{
              width: "100%",
            }}
          >
            {/* <Link to={`/home/${option.country_id}`}> */}
            <Box
              className={classes.Flags_main_div}
              onClick={() => {
                if (option.countryName === "Bermuda") {
                  navigate("/home");
                } else {
                  navigate("/ComingSoon", { state: option.image });
                }
              }}
            >
              <Box className={classes.option_div}>
                <Box key={option.country_id} className={classes.option_div}>
                  <img
                    alt={""}
                    className={classes.flag_div}
                    src={`${COUNTRYIMAGE_URL}/${option.country}`}
                  />
                  <Typography variant="h4" className={classes.flag_countyName}>
                    {option.countryName}
                  </Typography>
                </Box>
              </Box>
            </Box>
            {/* </Link> */}

            <Divider />
          </div>
        )}
        renderInput={(params) => (
          <TextField
            autoFocus={focus}
            onClick={() => {
              setIcon(icon);
            }}
            className={classes.option_textfield}
            {...params}
            placeholder="SELECT COUNTRY"
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password", // disable autocomplete and autofill
            }}
          />
        )}
      />
    </>
  );
};
export default Flags;
const useStyles = makeStyles((theme) => ({
  option_main_div: {
    fontSize: 15,
    position: "relative",
    "& .MuiAutocomplete-root": {
      borderColor: "grey",
    },
  },
  iconPos: {
    position: "absolute",
    right: "25.3%",
    top: "13%",
    zIndex: "99999",
  },
  Flags_main_div: {
    display: "flex",
    justifyContent: "flex-start",
    paddingBottom: "6px",
    opacity: "0.5",
    "&:hover": {
      transform: "scale(1.05)",
      transition: "0.5s",
      color: "black",
      opacity: "1",
    },
  },

  option_div: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  option_textfield: {
    "&:focus-within": {
      // backgroundColor: "#1E1E24",
      borderRadius: "10px",
      opacity: 1,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #484850",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #484850",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #484850",
      borderRadius: "10px",
    },
    "& .Mui-disabled": {
      color: "#FFFFFF",
      opacity: 0.6,
    },
    "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #484850",
    },
    //  wih
    width: "300px",
    "& .MuiOutlinedInput-root": {
      height: "41px",
      padding: "0",
      paddingLeft: "12px",
      backgroundColor: "white",
      borderRadius: "10px",
    },

    [theme.breakpoints.down("xs")]: {
      width: "275px",
    },
  },
  flag_div: {
    marginRight: "10px",
    width: "40px",
    boxShadow: "1px 0px 5px 0px #888888",
    borderRadius: "8px",
  },
  flag_countyName: {
    fontSize: "18px",
    color: "#575757",
  },
}));
