import React, { useRef } from "react";
import CN from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
/**
 * @dev simple input component
 * @props searchDoc
 * @props searchDocFullWidth
 */
const SearchBar = ({
  searchDoc,
  searchDocFullWidth,
  searchIcon,
  searchLil,
  change,
}) => {
  const classes = useStyles();
  const ref = useRef();
  const handleOrange = () => {
    ref.current.style.backgroundColor = "#EB9A28";
  };
  const handleGrey = () => {
    ref.current.style.backgroundColor = "#575757";
  };

  return (
    <div
      onMouseEnter={handleOrange}
      onMouseLeave={handleGrey}
      className={classes.alignItems}
    >
      <div
        className={CN({
          [classes.searchIcon]: searchIcon,
          [classes.searchLil]: searchLil,
        })}
        ref={ref}
      >
        <SearchIcon className={classes.heloIcons} />
      </div>
      {/* <input className={classes.searchDoc} placeholder="Search for document" /> */}
      <input
        onChange={change}
        className={CN({
          [classes.searchDocFullWidth]: searchDocFullWidth,
          [classes.searchDoc]: searchDoc,
        })}
        placeholder="SEARCH FOR DOCUMENT"
      />
    </div>
  );
};
export default SearchBar;
const useStyles = makeStyles((theme) => ({
  heloIcon: {
    height: "30px",
    width: "30px",
  },
  alignItems: {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    position: "relative",
    width: "100%",
  },
  searchIcon: {
    display: "flex",
    justifyContent: "center",
    textAlign: "left",
    alignItems: "center",
    right: "10px",
    backgroundColor: "#575757",
    boxshadow: "7px 7px 17px rgba(0, 0, 0, 0.05)",
    height: "43px",
    width: "43px",
    position: "absolute",
    left: "-10px",
    color: theme.palette.secondary.main,
    borderRadius: "10px",
    "&:hover": { backgroundColor: theme.palette.icon.main },
  },
  searchLil: {
    display: "flex",
    justifyContent: "center",
    textAlign: "left",
    alignItems: "center",
    right: "10px",
    backgroundColor: "#575757",
    boxshadow: "7px 7px 17px rgba(0, 0, 0, 0.05)",
    height: "35px",
    width: "35px",
    position: "absolute",
    left: "5px",
    color: theme.palette.secondary.main,
    borderRadius: "10px",
    "&:hover": { backgroundColor: theme.palette.icon.main },
  },
  searchDocFullWidth: {
    backgroundColor: "white",
    border: "none",
    borderRadius: "10px",
    color: "#3E3E3E",
    height: "45px",
    fontSize: "17px",
    padding: "10px",
    width: "400px",
    textAlign: "left",
    paddingLeft: "60px",
    transition: "transform 550ms",
    paddingTop: "10px",
    boxShadow: "7px 7px 17px rgba(0, 0, 0, 0.15)",
    outline: "none",
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "3px 3px 17px rgba(0, 0, 0, 0.15)",
      borderRadius: "10px",
    },
    [theme.breakpoints.down("md")]: {
      width: "300px",
    },
  },
  searchDoc: {
    backgroundColor: "white",
    border: "none",
    borderRadius: "10px",
    color: "black",
    height: "37px",
    fontSize: "17px",
    padding: "10px",
    marginLeft: "3px",
    width: "290px",
    textAlign: "left",
    paddingLeft: "60px",
    transition: "transform 550ms",
    paddingTop: "10px",

    outline: "none",
    "&:hover": {
      backgroundColor: "white",
      boxShadow: "3px 3px 17px rgba(0, 0, 0, 0.15)",
      borderRadius: "10px",
    },
    "&::placeholder": {
      color: "#c4c4c4",
      fontSize: "13px",
    },
  },
}));
