import { createSlice } from "@reduxjs/toolkit";
import { postPayment } from "./payment.actions";

const initialState = {
  isPaymentLoading: false,
  isPaymentPosted: false,
  isPaymentPostFailed: false,
  paymentData: {},
};

const payment = createSlice({
  name: "payment",
  initialState,
  extraReducers: {
    [postPayment.pending]: (state) => {
      state.isPaymentLoading = true;
    },
    [postPayment.fulfilled]: (state, action) => {
      state.isPaymentLoading = false;
      state.isPaymentPosted = true;
      state.paymentData = action.payload;
    },
    [postPayment.rejected]: (state) => {
      state.isPaymentLoading = false;
      state.isPaymentPostFailed = true;
    },
  },
});

export default payment.reducer;
