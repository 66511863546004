import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSearch } from "../../HTTP/api";

export const homeSearch = createAsyncThunk(
  "search/homeSearch",
  async (payload) => {
    try {
      const res = await getSearch(payload);

      return res.data;
    } catch (error) {
      throw error;
    }
  }
);
