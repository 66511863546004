import { Box, CircularProgress, makeStyles } from "@material-ui/core";

const Loading = () => {
  const classes = useStyles();

  return (
    <Box className={classes.loading}>
      <CircularProgress />
    </Box>
  );
};

export default Loading;

const useStyles = makeStyles((theme) => ({
  loading: {
    position: "absolute",
    top: 0,
    right: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(255,255,255,0.5)",
    display: "grid",
    placeContent: "center",
  },
}));
