import { createSlice } from "@reduxjs/toolkit";
import { getDocumentByCategory } from "./documentByCategory.actions";

const initialState = {
  areDocumentsByCategoryLoading: false,
  areDocumentsByCategoryLoaded: false,
  areDocumentsByCategoryFailed: false,
  documentsByCategory: [],
};

const documentsByCategory = createSlice({
  name: "documentsByCategory",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getDocumentByCategory.pending, (state) => {
      state.areDocumentsByCategoryLoading = true;
    });
    builder.addCase(getDocumentByCategory.fulfilled, (state, action) => {
      state.areDocumentsByCategoryLoaded = true;
      state.documentsByCategory = action.payload[0].result;
      state.areDocumentsByCategoryLoading = false;
      console.log(action.payload);
    });
    builder.addCase(getDocumentByCategory.rejected, (state) => {
      state.areDocumentsByCategoryLoading = false;
      state.areDocumentsByCategoryFailed = true;
    });
  },
});

export default documentsByCategory.reducer;
