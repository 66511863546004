import { makeStyles } from "@material-ui/core";
import Header from "../../src/components/header";
import Footer from "../../src/components/footer";

const UserLayout = ({ Navbar, children }) => {
  const classes = useStyles();
  return (
    <div>
      <Header />
      <div className={classes.root}>{children}</div>
      <Footer />
    </div>
  );
};

export default UserLayout;

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 2),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0, 0.5),
    },
  },
}));
