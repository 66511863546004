import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllFilledDocumentsApi } from "../../HTTP/api";

export const getAllFilledDocuments = createAsyncThunk(
  "filledDocument/getAllFilledDocuments",
  async (payload) => {
    const res = await getAllFilledDocumentsApi(payload);

    return res.data;
  }
);
