import SignIn from "./components/auth/SignIn";
import SignUp from "./components/auth/SignUp";



const UniversalDialogs = () => {
  return (
    <>
      <SignIn/>
      <SignUp/>
    </>
  );
};

export default UniversalDialogs;
