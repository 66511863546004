import image1 from "../../assest/images/Logo.png";
import image2 from "../../assest/images/Group_665.png";
import image3 from "../../assest/images/Group_666.png";
const data = [
  {
    id: "01",
    image: image1,
    title: "CHOOSE A TEMPLATE",
    description: "Choose a document from any of our available documents",
  },
  {
    id: "02",
    image: image2,
    title: "COMPLETE THE DOCUMENT",
    description:
      "Answer a few questions and your document is created automatically.",
  },
  {
    id: "03",
    image: image3,
    title: "SAVE - PRINT",

    description: `
  <div class="textIndent">Your document is ready! Use it however you wish</div>`,
  },
];
export default data;
