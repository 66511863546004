import { createAsyncThunk } from "@reduxjs/toolkit";
import { editBillingAddressAPI } from "./apis";

export const editBillingAddress = createAsyncThunk(
  "editbillingaddress",
  async (payload) => {
    const res = await editBillingAddressAPI(payload);
    return res.data;
  }
);
