import React from "react";
import Box from "@material-ui/core/Box";
import { Typography } from "@material-ui/core";
import Button from "../../components/buttons/index";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import { Link } from "react-router-dom";
import useStyles from "./style";

function ConfirmDelete() {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.mainCont}>
        <Box className={classes.forMainHeading}>
          <Typography>confirm deletion</Typography>
        </Box>
        <Box className={classes.forDelText}>
          <Typography>
            are you sure you want to delete your account, "document name"
          </Typography>
        </Box>
        <Box className={classes.forBtns}>
          <Link to="/myaccount">
            <Button variant="outlined" className={classes.forBtn1}>
              Cancel
            </Button>
          </Link>
          <Link to="/myaccount">
            <Button className={classes.forBtn2}>
              <DeleteForeverOutlinedIcon /> Delete Account
            </Button>
          </Link>
        </Box>
      </Box>
    </>
  );
}

export default ConfirmDelete;
