import { createSlice } from "@reduxjs/toolkit";
import { homeSearch } from "./search.action";

const initialState = {
  ishomeSearchLoading: false,
  ishomeSearchLoadingFailed: false,
  ishomeSearchLoadingSuccess: false,
  data: [],
};

const searchHome = createSlice({
  name: "homeSearch",
  initialState,
  extraReducers: {
    [homeSearch.pending]: (state) => {
      state.ishomeSearchLoading = true;
      state.ishomeSearchLoadingSuccess = false;
    },
    [homeSearch.fulfilled]: (state, action) => {
      state.ishomeSearchLoading = false;
      state.ishomeSearchLoadingSuccess = true;
      state.data = action.payload.data;
    },
    [homeSearch.rejected]: (state) => {
      state.ishomeSearchLoading = false;
      state.ishomeSearchLoadingFailed = true;
      state.ishomeSearchLoadingSuccess = false;
    },
  },
});

export default searchHome.reducer;
