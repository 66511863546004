import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  upperone: {
    width: "100%",
    height: 150,
    backgroundColor: "#E5E5E5",
  },
  main: {
    width: "50%",
    position: "relative",
    top: -130,
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "70%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },

  outercont: {
    postion: "relative",
    padding: 20,
  },
  heading: {
    textTransform: "upperCase",
    fontWeight: 600,
    fontSize: 24,
    paddingLeft: 70,
    color: theme.palette.text.primary,
    marginTop: 6,
    fontFamily: theme.typography.fontFamily,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 80,
    },
  },
  forLinks: {
    paddingLeft: 90,
    marginTop: 12,
    marginBottom: 37,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 100,
    },
    "& a ": {
      // "&:hover": {
      //   color: "blue",
      //   textDecoration:'underline !important'
      // },
    },
    "& a >ul >li": {
      color: theme.palette.text.primary,
      padding: 2,
      fontFamily: theme.typography.fontFamily,
      fontWeight: 400,
      fontSize: 20,
      "&:hover": {
        color: "blue",
        textDecoration:'underline !important'
      },
    },
  },
  divider: {
    width: "85%",
    margin: "auto",
    color: "#E6E6E6",
  },
}));
export default useStyles;
