import React from "react";
import Box from "@material-ui/core/Box";
import { Typography } from "@material-ui/core";
import RippleBckground from "../../components/ripplwbackground";
import Button from "../../components/buttons/index";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import { Link } from "react-router-dom";
import useStyles from "./style";

function DeleteAccount() {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <RippleBckground />
      <Box className={classes.mainCont}>
        <Box className={classes.forMainHeading}>
          <Typography>
            Please confirm that you want to delete your account
          </Typography>
        </Box>
        <Box className={classes.forDelText}>
          <Typography>
            Do you want to permanently delete your account?
          </Typography>
        </Box>
        <Box className={classes.forBtns}>
          <Link to="/myaccount">
            <Button variant="outlined" className={classes.forBtn1}>
              Cancel
            </Button>
          </Link>

          <Button className={classes.forBtn2}>
            <DeleteForeverOutlinedIcon /> Delete Account
          </Button>
        </Box>
      </Box>
    </div>
  );
}

export default DeleteAccount;
