import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import Button from "../../components/buttons";
import BorderColorOutlinedIcon from "@material-ui/icons/BorderColorOutlined";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import TransitionsModal from "../signatureModal";
function SignatureForm() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <Box className={classes.main_container}>
      <Box className={classes.main_inner_container}>
        <Box textAlign="center">
          <Button
            className={classes.signatureButton}
            variant="outlined"
            onClick={handleOpen}
            startIcon={
              <BorderColorOutlinedIcon className={classes.signatureIcon} />
            }
          >
            <Typography variant="h6">
              start by creating your signature
            </Typography>
          </Button>
        </Box>
        <TransitionsModal open={open} setOpen={setOpen} />
        <Box ml={1} mt={5}>
          <Typography variant="h3">Add a new signer</Typography>
        </Box>
        <Box mt={1} textAlign="center">
          {" "}
          <Button className={classes.addpersonButton} variant="outlined">
            <PersonAddIcon fontSize="large" />
            <Typography variant="h6">Add a new person</Typography>
          </Button>
        </Box>
        <Box mt={4} textAlign="center">
          {" "}
          <Button variant="outlinedButton">
            <Typography className={classes.confirmButton} variant="h6">
              Confirm and continue
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default SignatureForm;
const useStyles = makeStyles((theme) => ({
  main_container: {
    position: "relative",
    top: "-60px",
    width: 300,
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
      width: "100%",
    },
  },
  main_inner_container: {
    width: 300,
    boxShadow: "rgba(17, 17, 26, 0.1) 0px 0px 16px",
    background: theme.palette.text.secondary,
    padding: 13,

    height: "290px",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      width: "100%",
    },
  },

  signatureIcon: {
    color: theme.palette.main.primary,
    fontSize: "23px !important",
  },
  signatureButton: {
    textAlign: "initial",
    height: "43px",
    textTransform: "capitalize",
    fontSize: 14,
    width: "95%",
  },
  addpersonButton: {
    width: "95%",
    color: theme.palette.background.primary,
    justifyContent: "flex-start",
  },
  confirmButton: {
    fontsize: 18,
    fontWeight: 800,
  },
}));
