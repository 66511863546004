import { combineReducers } from "@reduxjs/toolkit";
import AllcountriesFlag from "../Features/allFlag/allflag.slicer";
import getDocByCountry from "../Features/getDocByCountry/getDocByCountry.slicer";
import signUpSlicer from "../Features/signUp/signUp.slicer";
import loginSlicer from "../Features/signIn/signIn.slicer";
import billingAddress from "../Features/billingAddress/billingAddress.slicer";
import getSingleDocByIdSlicer from "../Features/getSingleDocById/getSingleDocById.slicer";
import searchHome from "../Features/search/search.slicer";
import paymentSlicer from "../Features/payment/payment.slicer";
import filledDocumentSlicer from "../Features/filledDocument/filledDocument.slicer";
import documentsByCategorySlicer from "../Features/documentByCategory/documentByCategory.slicer";

const rootReducer = combineReducers({
  AllcountriesFlag: AllcountriesFlag,
  SignUp: signUpSlicer,
  getDocByCountry: getDocByCountry,
  LoginSlicer: loginSlicer,
  BillingAddress: billingAddress,
  getSingleDocById: getSingleDocByIdSlicer,
  searchHome: searchHome,
  filledDocument: filledDocumentSlicer,
  payment: paymentSlicer,
  documentsByCategory: documentsByCategorySlicer,
});

export default rootReducer;
