import { ClickAwayListener } from "@material-ui/core";
import SearchBar from "../SearchBar/SearchBar";
import SearchDropDown from "../SearchDropDown";
import { useDispatch } from "react-redux";
import { homeSearch } from "../../Features/search/search.action";
import { useState } from "react";

const SearchBothDocs = () => {
  const [searchText, setSearchText] = useState(null);

  const dispatch = useDispatch();

  const handleChnageSearchText = (e) => {
    setSearchText(e.target.value);
    dispatch(homeSearch(e.target.value));
  };

  const handleClickAway = () => {
    setSearchText(null);
  };

  return (
    <div style={{ position: "relative" }}>
      <SearchBar
        searchIcon
        searchDocFullWidth
        change={handleChnageSearchText}
      />
      {searchText && (
        <ClickAwayListener
          mouseEvent="onMouseDown"
          touchEvent="onTouchStart"
          onClickAway={handleClickAway}
        >
          <div>
            <SearchDropDown />
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default SearchBothDocs;
