import { legalFinancal } from "../../HTTP/urls";

export const postSignUp = async (payload) => {
  await legalFinancal.post("/api/users/singUp", payload, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
  });
};
